import React, { useState, useEffect } from 'react'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip';
import Select from "react-select";
import axios from 'axios';
import { TagsInput } from "react-tag-input-component";

import './AddPatient.css'
import { useStateContext } from '../../../context/AppContext';

export default function AddPatient() {
    const navigate = useNavigate();
    const location = useLocation();
    const [pageLoading, setpageLoading] = useState(true)
    const [data, setdata] = useState('')
    const [data2, setdata2] = useState([])

    const { userToken } = useStateContext();
    const [totalPatients, settotalPatients] = useState('')
    const [patientId, setpatientId] = useState('')

    const [isLoading, setisLoading] = useState(false)
    const [isLoading2, setisLoading2] = useState(false)
    const [name, setname] = useState('')
    const [gender, setgender] = useState('')
    const [age, setage] = useState('')
    const [medicalCondition, setmedicalCondition] = useState([])
    const [medicationCrcl, setmedicationCrcl] = useState([])
    const [childPughScore, setchildPughScore] = useState('')
    const [hba1c, sethba1c] = useState('')
    const [fastingBlood, setfastingBlood] = useState('')
    const [ldl, setldl] = useState('')
    const [hdl, sethdl] = useState("")
    const [triglycerides, settriglycerides] = useState('')
    const [hdlRatio, sethdlRatio] = useState('')
    const [height, setheight] = useState('')
    const [weight, setweight] = useState('')
    const [bmi, setbmi] = useState('')
    const [sodium, setsodium] = useState('')
    const [potassium, setpotassium] = useState('')
    const [bloodPressure, setbloodPressure] = useState('')
    const [creatineClearance, setcreatineClearance] = useState('')
    const [ferritin, setferritin] = useState('')
    const [tsh, settsh] = useState('')
    const [t4, sett4] = useState('')
    const [t3, sett3] = useState('')
    const [vitaminD, setvitaminD] = useState('')
    const [vitaminB12, setvitaminB12] = useState('')
    const [licenseNum, setlicenseNum] = useState('')

    const [nameError, setnameError] = useState('')
    const [ageError, setageError] = useState('')
    const [genderError, setgenderError] = useState('')
    const [medConditionError, setmedConditionError] = useState('')
    const [medCrclError, setmedCrclError] = useState('')
    const [childPughError, setchildPughError] = useState('')
    const [a1cError, seta1cError] = useState('')
    const [fastingBloodError, setfastingBloodError] = useState('')
    const [ldlError, setldlError] = useState('')
    const [hdlError, sethdlError] = useState('')
    const [triglyceridesError, settriglyceridesError] = useState('')
    const [hdlRatioError, sethdlRatioError] = useState('')
    const [heightError, setheightError] = useState('')
    const [weightError, setweightError] = useState('')
    const [bmiError, setbmiError] = useState('')
    const [sodiumError, setsodiumError] = useState('')
    const [potassiumError, setpotassiumError] = useState('')
    const [bloodPressError, setbloodPressError] = useState('')
    const [creatineError, setcreatineError] = useState('')
    const [ferritinError, setferritinError] = useState('')
    const [tshError, settshError] = useState('')
    const [t4Error, sett4Error] = useState('')
    const [t3Error, sett3Error] = useState('')
    const [vitaminDError, setvitaminDError] = useState('')
    const [vitaminB12Error, setvitaminB12Error] = useState('')
    const [licenseNoError, setlicenseNoError] = useState('')


    const handleSubmit = () => {
        if (!name) { return setnameError("Please enter the patient name") };
        if (!age) { return setageError("Please select the patient's date of birth") };
        if (!gender) { return setgenderError("Please select gender of the patient") };
        if (medicalCondition.length == 0) { return setmedConditionError("Please select the medical conditions") };
        // if (!medicationCrcl) { return setmedCrclError("Please enter the medications crcl") };
        // if (!a1c) { return seta1cError("Please enter the A1c") };
        // if (!fastingBlood) { return setfastingBloodError("Please enter the fasting blood sugar") };
        // if (!ldl) { return setldlError("Please enter the LDL") };
        // if (!hdl) { return sethdlError("Please enter the HDL") };
        // if (!triglycerides) { return settriglyceridesError("Please enter the triglycerides") };
        // if (!hdlRatio) { return sethdlRatioError("Please enter the HDL ratio") };
        if (!height) { return setheightError("Please enter the height of patient") };
        if (!weight) { return setweightError("Please enter the weight of patient") };
        if (!bmi) { return setbmiError("Please enter the BMI") };
        // if (!sodium) { return setsodiumError("Please enter the sodium") };
        // if (!potassium) { return setpotassiumError("Please enter the potassium") };
        // if (!bloodPressure) { return setbloodPressError("Please enter the blood pressure") };
        // if (!creatineClearance) { return setcreatineError("Please enter the creatine clearance") };
        // if (!childPughScore) { return setchildPughError("Please enter the child pugh score") };
        // if (!ferritin) { return setferritinError("Please enter the ferritin") };
        // if (!tsh) { return settshError("Please enter the TSH") };
        // if (!t4) { return sett4Error("Please enter the T4") };
        // if (!t3) { return sett3Error("Please enter the t3") };
        // if (!vitaminD) { return setvitaminDError("Please enter the vitamin d") };
        // if (!vitaminB12) { return setvitaminB12Error("Please enter the vitamin b12") };
        // if (!licenseNum) { return setlicenseNoError("Please enter the license number") };

        if (nameError || ageError || genderError || medConditionError
            || heightError || weightError || bmiError)
            return

        let newMedCon = medicalCondition.map(t => t.value)
        setisLoading(true)
        const formData = {
            name, gender, age, medicalCondition: newMedCon, medicationCrcl, hba1c,
            fastingBlood, ldl, hdl, triglycerides, hdlRatio, height, weight, bmi, sodium, potassium,
            bloodPressure, creatineClearance, childPughScore, ferritin, tsh, t4, t3, vitaminD,
            vitaminB12, patientNo: totalPatients + 1
        }

        axios.post(`${process.env.REACT_APP_API_URL}/patient/insert`, formData, { headers: { token: userToken } })
            .then((res) => {
                axios.put(`${process.env.REACT_APP_API_URL}/auth/update`, { totalPatients: totalPatients + 1 }, { headers: { token: userToken } })
                    .then((response) => {
                        // getAIRecommendation("Added", res?.data)
                        toast.success(`Patient Added Successfully!`);
                        setisLoading(false)
                        navigate(`/dashboard/patient-details/${res?.data?.patientNo}?purpose=${encodeURIComponent("get-recommendation")}`)
                    })
                    .catch((error) => { console.log(error); setisLoading(false) })
            }).catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }

    const compareFields = () => {
        let newMedCon = medicalCondition.map(t => t.value)
        return (
            name !== data.name || age !== data.age || gender !== data.gender ||
            JSON.stringify(newMedCon) !== data.medicalConditions || JSON.stringify(medicationCrcl) !== data.medicationsCrCl ||
            hba1c !== data.hba1c || fastingBlood !== data.fastingBlood || ldl !== data.LDL ||
            hdl !== data.HDL || triglycerides !== data.triglycerides || hdlRatio !== data.HDLRatio ||
            height !== data.height || weight !== data.weight || bmi !== data.BMI ||
            sodium !== data.sodium || potassium !== data.potassium || bloodPressure !== data.bloodPressure
            || creatineClearance !== data.creatineClearance || childPughScore !== data.childPughScore ||
            ferritin !== data.ferritin || tsh !== data.TSH || t4 !== data.T4 || t3 !== data.T3 ||
            vitaminD !== data.vitaminD || vitaminB12 !== data.vitaminb12 
            // || licenseNum !== data.licenseNum
        );
    };

    const handleUpdate = () => {
        if (!compareFields()) {
            toast.error(`No changes detected. Please make changes before updating.`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        if (!name) { return setnameError("Please enter the patient name") };
        if (!age) { return setageError("Please select the patient's date of birth") };
        if (!gender) { return setgenderError("Please select gender of the patient") };
        if (medicalCondition.length == 0) { return setmedConditionError("Please select the medical conditions") };
        // if (!medicationCrcl) { return setmedCrclError("Please enter the medications crcl") };
        // if (!a1c) { return seta1cError("Please enter the A1c") };
        // if (!fastingBlood) { return setfastingBloodError("Please enter the fasting blood sugar") };
        // if (!ldl) { return setldlError("Please enter the LDL") };
        // if (!hdl) { return sethdlError("Please enter the HDL") };
        // if (!triglycerides) { return settriglyceridesError("Please enter the triglycerides") };
        // if (!hdlRatio) { return sethdlRatioError("Please enter the HDL ratio") };
        if (!height) { return setheightError("Please enter the height of patient") };
        if (!weight) { return setweightError("Please enter the weight of patient") };
        if (!bmi) { return setbmiError("Please enter the BMI") };
        // if (!sodium) { return setsodiumError("Please enter the sodium") };
        // if (!potassium) { return setpotassiumError("Please enter the potassium") };
        // if (!bloodPressure) { return setbloodPressError("Please enter the blood pressure") };
        // if (!creatineClearance) { return setcreatineError("Please enter the creatine clearance") };
        // if (!childPughScore) { return setchildPughError("Please enter the child pugh score") };
        // if (!ferritin) { return setferritinError("Please enter the ferritin") };
        // if (!tsh) { return settshError("Please enter the TSH") };
        // if (!t4) { return sett4Error("Please enter the T4") };
        // if (!t3) { return sett3Error("Please enter the t3") };
        // if (!vitaminD) { return setvitaminDError("Please enter the vitamin d") };
        // if (!vitaminB12) { return setvitaminB12Error("Please enter the vitamin b12") };
        // if (!licenseNum) { return setlicenseNoError("Please enter the license number") };

        if (nameError || ageError || genderError || medConditionError
            || heightError || weightError || bmiError
        )
            return

        let newMedCon = medicalCondition.map(t => t.value)
        setisLoading2(true)
        const formData = {
            name, gender, age, medicalCondition: newMedCon, medicationCrcl, hba1c,
            fastingBlood, ldl, hdl, triglycerides, hdlRatio, height, weight, bmi, sodium, potassium,
            bloodPressure, creatineClearance, childPughScore, ferritin, tsh, t4, t3, vitaminD,
            vitaminB12
        }


        axios.put(`${process.env.REACT_APP_API_URL}/patient/update/${patientId}`, formData, { headers: { token: userToken } })
            .then((res) => {
                // getAIRecommendation('Updated')
                toast.success(`Patient Updated Successfully!`);
                setisLoading2(false)
                navigate(`/dashboard/patient-details/${location?.state?.id}?purpose=${encodeURIComponent("get-recommendation")}`)
            }).catch((err) => {
                console.log(err)
                setisLoading2(false)
            })
    }


    const getAIRecommendation = (Name, elem) => {
        let newMedCon = medicalCondition.map(t => t.value)
        let formData = {
            name, gender, age, medicalCondition: newMedCon, medicationCrcl, hba1c,
            fastingBlood, ldl, hdl, triglycerides, hdlRatio, height, weight, bmi, sodium, potassium,
            bloodPressure, creatineClearance, childPughScore, ferritin, tsh, t4, t3, vitaminD,
            vitaminB12, licenseNum
        }

        axios.post(`${process.env.REACT_APP_API_URL}/askgpt`, formData, { headers: { token: userToken } })
            .then((response) => {
                if (Name == 'Added') {
                    formData.patientId = elem.patientId;
                    formData.patientNo = totalPatients + 1;
                } else {
                    formData.patientId = patientId;
                    formData.patientNo = location?.state?.id;
                }
                formData.recommendation = response.data;
                let formData2 = formData;
                axios.post(`${process.env.REACT_APP_API_URL}/recommendation/save`, formData2, { headers: { token: userToken } })
                    .then((response) => {
                        toast.success(`Patient ${Name} Successfully!`, {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        if (Name == 'Added') {
                            setisLoading(false)
                            navigate(`/dashboard/patient-details/${elem.patientNo}`)
                        } else {
                            setisLoading2(false)
                            navigate(`/dashboard/patient-details/${location?.state?.id}`)
                        }
                    }).catch((err) => {
                        console.log(err)
                    })

            }).catch((err) => {
                console.log(err)
            })
    }

    const handleNameChange = (e) => {
        setname(e.target.value)
        const nameRegex = /^[A-Za-z ]{4,}$/;
        if (!nameRegex.test(e.target.value)) {
            return setnameError('Please enter only letters and the name must be at least 4 characters long')
        }
        setnameError('')
    }
    const handleAgeChange = (e) => {
        setage(e.target.value)
        if (!e.target.value) {
            return setageError(`Please select patient's date of birth`)
        }
        setageError('')
    }
    const handleGenderChange = (e) => {
        setgender(e.target.value)
        if (!e.target.value) {
            return setgenderError(`Please select gender of the patient`)
        }
        setgenderError('')
    }

    const handleMedicalConditionChange = (event) => {
        // if (event.target.value) {
        //     setmedConditionError('')
        //     const selectedOptions = Array.from(event.target.selectedOptions);
        //     const selectedValues = selectedOptions.map(option => option.value);
        //     setmedicalCondition(selectedValues);
        // } else {
        //     setmedicalCondition([])
        // }
        if (event.length !== 0) {
            setmedConditionError('')
            setmedicalCondition(event);
        } else {
            setmedicalCondition([])
        }
    };

    const handleMedCrclChange = (e) => {
        setmedicationCrcl(e)
        // if (!e.target.value) {
        //     return setmedCrclError(`Please enter the medications crcl`)
        // }
        // setmedCrclError('')
    }
    const handleA1cChange = (e) => {
        sethba1c(e.target.value)
        // if (!e.target.value) {
        //     return seta1cError(`Please enter the a1c`)
        // }
        // seta1cError('')
    }
    const handleFastingBloodChange = (e) => {
        setfastingBlood(e.target.value)
        // if (!e.target.value) {
        //     return setfastingBloodError(`Please enter the fasting blood sugar`)
        // }
        // setfastingBloodError('')
    }
    const handleLdlChange = (e) => {
        setldl(e.target.value)
        // if (!e.target.value) {
        //     return setldlError(`Please enter the LDL`)
        // }
        // setldlError('')
    }
    const handleHdlChange = (e) => {
        sethdl(e.target.value)
        // if (!e.target.value) {
        //     return sethdlError(`Please enter the HDL`)
        // }
        // sethdlError('')
    }
    const handleTriglyceridesChange = (e) => {
        settriglycerides(e.target.value)
        // if (!e.target.value) {
        //     return settriglyceridesError(`Please enter the triglycerides`)
        // }
        // settriglyceridesError('')
    }
    const handleHdlRatioChange = (e) => {
        sethdlRatio(e.target.value)
        // if (!e.target.value) { return sethdlRatioError(`Please enter the HDL Ratio`) }
        // sethdlRatioError('')
    }
    const handleHeightChange = (e) => {
        setheight(e.target.value)
        // const heightRegex = /^(?=.*\d)(?=.*(?:cm|cms))[\d.\s]+(?:cm|cms)?$/;
        const heightRegex = /^\d+(\.\d+)?$/;
        if (!heightRegex.test(e.target.value)) {
            return setheightError('Please enter height as a number, allowing only digits and "." for decimals')
        }
        setheightError('')
    }
    const handleWeightChange = (e) => {
        setweight(e.target.value)
        // const weightRegex = /^(?=.*\d)(?=.*(?:pound|pounds))[\d.\s]+(?:pound|pounds)?$/;
        const weightRegex = /^\d+(\.\d+)?$/
        if (!weightRegex.test(e.target.value)) {
            return setweightError('Please enter weight as a number, allowing only digits and "." for decimals')
        }
        setweightError('')
    }
    const handleBmiChange = (e) => {
        setbmi(e.target.value)
        if (!e.target.value) { return setbmiError(`Please enter the BMI`) }
        setbmiError('')
    }
    const handleSodiumChange = (e) => {
        setsodium(e.target.value)
        // if (!e.target.value) { return setsodiumError(`Please enter the sodium`) }
        // setsodiumError('')
    }
    const handlePotassiumChange = (e) => {
        setpotassium(e.target.value)
        // if (!e.target.value) { return setpotassiumError(`Please enter the potassium`) }
        // setpotassiumError('')
    }
    const handleBloodPressChange = (e) => {
        setbloodPressure(e.target.value)
        // if (!e.target.value) { return setbloodPressError(`Please enter the blood pressure`) }
        // setbloodPressError('')
    }
    const handleCreatineChange = (e) => {
        setcreatineClearance(e.target.value)
        // if (!e.target.value) { return setcreatineError(`Please enter the creatine clearance`) }
        // setcreatineError('')
    }
    const handleChildPughChange = (e) => {
        setchildPughScore(e.target.value)
        // if (!e.target.value) { return setchildPughError(`Please enter the child pugh score`) }
        // setchildPughError('')
    }
    const handleFerritinChange = (e) => {
        setferritin(e.target.value)
        // if (!e.target.value) { return setferritinError(`Please enter the ferritin`) }
        // setferritinError('')
    }
    const handleTshChange = (e) => {
        settsh(e.target.value)
        // if (!e.target.value) { return settshError(`Please enter the TSH`) }
        // settshError('')
    }
    const handleT4Change = (e) => {
        sett4(e.target.value)
        // if (!e.target.value) { return sett4Error(`Please enter the T4`) }
        // sett4Error('')
    }
    const handleT3Change = (e) => {
        sett3(e.target.value)
        // if (!e.target.value) { return sett3Error(`Please enter the T3`) }
        // sett3Error('')
    }
    const handleVitaminDChange = (e) => {
        setvitaminD(e.target.value)
        // if (!e.target.value) { return setvitaminDError(`Please enter the vitamin d`) }
        // setvitaminDError('')
    }
    const handleVitaminB12Change = (e) => {
        setvitaminB12(e.target.value)
        // if (!e.target.value) { return setvitaminB12Error(`Please enter the vitamin b12`) }
        // setvitaminB12Error('')
    }
    const handleLicenseChange = (e) => {
        setlicenseNum(e.target.value)
        if (!e.target.value) { return setlicenseNoError(`Please enter the license number`) }
        setlicenseNoError('')
    }



    useEffect(() => {

        axios.get(`${process.env.REACT_APP_API_URL}/auth/user-info`, { headers: { token: userToken } })
            .then((response) => {
                settotalPatients(response?.data?.totalPatients)
                if (location?.state?.id) {
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/patient/get`, { headers: { token: userToken } })
                        .then((res) => {
                            const filter = res.data.filter((t, i) => t.patientNo == location?.state?.id)
                            setname(filter[0]?.name); setage(filter[0]?.age); setgender(filter[0]?.gender)
                            setmedicationCrcl(JSON.parse(filter[0]?.medicationsCrCl)); sethba1c(filter[0]?.hba1c);
                            setfastingBlood(filter[0]?.fastingBlood); setldl(filter[0]?.LDL)
                            sethdl(filter[0]?.HDL); settriglycerides(filter[0]?.triglycerides); sethdlRatio(filter[0]?.HDLRatio)
                            setheight(filter[0]?.height); setweight(filter[0]?.weight); setbmi(filter[0]?.BMI)
                            setsodium(filter[0]?.sodium); setpotassium(filter[0]?.potassium); setbloodPressure(filter[0]?.bloodPressure)
                            setcreatineClearance(filter[0]?.creatineClearance); setchildPughScore(filter[0]?.childPughScore)
                            setferritin(filter[0]?.ferritin); settsh(filter[0]?.TSH); sett4(filter[0]?.T4); sett3(filter[0]?.T3)
                            setvitaminD(filter[0]?.vitaminD); setvitaminB12(filter[0]?.vitaminb12); setpatientId(filter[0]?._id)
                            // setlicenseNum(filter[0]?.licenseNum);
                            setdata(filter[0])
                            setpageLoading(false);
                            let medCondition = JSON.parse(filter[0]?.medicalConditions)
                            let medicalCondition = medCondition.map(item => ({ label: item, value: item }));
                            setmedicalCondition(medicalCondition);
                        })
                        .catch((error) => console.log(error));
                }
            })
            .catch((error) => console.log(error));

        axios
            .get(`${process.env.REACT_APP_API_URL}/medCondition/get`, { headers: { token: userToken } })
            .then((res) => {
                const mutilOptions = res.data.map((val) => {
                    const itemReturn = {
                        label: val.MedicalCondition,
                        value: val.MedicalCondition,
                    };
                    return itemReturn;
                });
                setdata2(mutilOptions);
                if (!location?.state?.id) { setpageLoading(false) }
            })
            .catch((error) => console.log(error));
    }, [])


    useEffect(() => {
        if (height && weight) {
            const weightInKg = parseFloat(weight.replace(/[^0-9.]/g, '')) * 0.453592;
            const heightInMeters = parseFloat(height.replace(/[^0-9.]/g, '')) / 100;
            if (!isNaN(weightInKg) && !isNaN(heightInMeters)) {
                const calculatedBmi = weightInKg / (heightInMeters * heightInMeters);
                setbmi(calculatedBmi.toFixed(2));
            } else {
                setbmi(null);
            }
        }
    }, [height, weight])

    return (
        <div className='addPatient-main'>
            {
                pageLoading ? <div className='addPatient-pageLoader'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                    :
                    <>
                        <i className="fa-solid fa-arrow-left addPatient-backBtn" onClick={() => navigate(-1)}></i>
                        <h3 className='db-addNew-title'>{location?.state?.id ? 'Upate Patient' : 'Add New Patient'}</h3>
                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Name</label>
                                    <div className='ap-div'>
                                        <input type="text" className="form-control" style={{ border: nameError && '1px solid #e4002b' }} value={name} onChange={handleNameChange} id="exampleFormControlInput1" placeholder="" />
                                        {nameError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip1"
                                                data-tooltip-content={nameError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip1" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Date of birth</label>
                                    <div className='ap-div'>
                                        <input type="date" className="form-control" style={{ border: ageError && '1px solid #e4002b' }} value={age} onChange={handleAgeChange} id="exampleFormControlInput1" placeholder="" />
                                        {ageError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip2"
                                                data-tooltip-content={ageError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip2" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className="form-group">
                                        <label className='addPatient-inp-label'>Gender</label>
                                        <div className='ap-div2'>
                                            <div className='addPatient'>
                                                <input type="radio" id="customRadioInline1" value="Male" checked={gender === 'Male'} onChange={handleGenderChange} name="customRadioInline1" className="custom-control-input" /> Male
                                            </div>
                                            <div className='addPatient'>
                                                <input type="radio" id="customRadioInline1" value="Female" checked={gender === 'Female'} onChange={handleGenderChange} name="customRadioInline1" className="custom-control-input" /> Female
                                            </div>
                                            <div className='addPatient'>
                                                <input type="radio" id="customRadioInline1" value="Other" checked={gender === 'Other'} onChange={handleGenderChange} name="customRadioInline1" className="custom-control-input" /> Other
                                            </div>
                                            {genderError && (
                                                <i
                                                    className="fa-solid fa-circle-info ap-icon2"
                                                    data-tooltip-id="my-tooltip3"
                                                    data-tooltip-content={genderError}
                                                    data-tooltip-place="top"
                                                ></i>
                                            )}
                                            <Tooltip id="my-tooltip3" />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Medical conditions</label>
                                    <div className="ap-div">
                                        <Select
                                            onChange={handleMedicalConditionChange}
                                            options={data2}
                                            value={medicalCondition}
                                            className="js-example-basic-single col-sm-12"
                                            isMulti
                                        />
                                        {medConditionError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip4"
                                                data-tooltip-content={medConditionError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip4" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Medications</label>
                                    <div className="ap-div">
                                        <TagsInput
                                            value={medicationCrcl}
                                            onChange={handleMedCrclChange}
                                            placeHolder="Enter Medications"
                                        />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>HBA1c</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: a1cError && '1px solid #e4002b' }} value={hba1c} onChange={handleA1cChange} id="exampleFormControlInput1" placeholder="" />
                                        {a1cError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip6"
                                                data-tooltip-content={a1cError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip6" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Fasting blood sugar</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: fastingBloodError && '1px solid #e4002b' }} value={fastingBlood} onChange={handleFastingBloodChange} id="exampleFormControlInput1" placeholder="" />
                                        {fastingBloodError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip7"
                                                data-tooltip-content={fastingBloodError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip7" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>LDL</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: ldlError && '1px solid #e4002b' }} value={ldl} onChange={handleLdlChange} id="exampleFormControlInput1" placeholder="" />
                                        {ldlError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip7"
                                                data-tooltip-content={ldlError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip7" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>HDL</label>
                                    <div className="ap-div">
                                        <input type="text" style={{ border: hdlError && '1px solid #e4002b' }} className="form-control" value={hdl} onChange={handleHdlChange} id="exampleFormControlInput1" placeholder="" />
                                        {hdlError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip8"
                                                data-tooltip-content={hdlError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip8" />
                                    </div>
                                </div><div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Triglycerides</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: triglyceridesError && '1px solid #e4002b' }} value={triglycerides} onChange={handleTriglyceridesChange} id="exampleFormControlInput1" placeholder="" />
                                        {triglyceridesError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip8"
                                                data-tooltip-content={triglyceridesError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip8" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>HDL ratio</label>
                                    <div className="ap-div">
                                        <input type="text" style={{ border: hdlRatioError && '1px solid #e4002b' }} className="form-control" value={hdlRatio} onChange={handleHdlRatioChange} id="exampleFormControlInput1" placeholder="" />
                                        {hdlRatioError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip9"
                                                data-tooltip-content={hdlRatioError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip9" />
                                    </div>
                                </div><div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Height (cm)</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: heightError && '1px solid #e4002b' }} value={height} onChange={handleHeightChange} id="exampleFormControlInput1" placeholder="" />
                                        {heightError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip10"
                                                data-tooltip-content={heightError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip10" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Weight (pound)</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: weightError && '1px solid #e4002b' }} value={weight} onChange={handleWeightChange} id="exampleFormControlInput1" placeholder="" />
                                        {weightError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip11"
                                                data-tooltip-content={weightError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip11" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>BMI</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: bmiError && '1px solid #e4002b' }} value={bmi} onChange={handleBmiChange} id="exampleFormControlInput1" placeholder="" />
                                        {bmiError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip12"
                                                data-tooltip-content={bmiError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip12" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Sodium</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: sodiumError && '1px solid #e4002b' }} value={sodium} onChange={handleSodiumChange} id="exampleFormControlInput1" placeholder="" />
                                        {sodiumError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip13"
                                                data-tooltip-content={sodiumError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip13" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Potassium</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: potassiumError && '1px solid #e4002b' }} value={potassium} onChange={handlePotassiumChange} id="exampleFormControlInput1" placeholder="" />
                                        {potassiumError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip14"
                                                data-tooltip-content={potassiumError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip14" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Blood pressure</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: bloodPressError && '1px solid #e4002b' }} value={bloodPressure} onChange={handleBloodPressChange} id="exampleFormControlInput1" placeholder="" />
                                        {bloodPressError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip15"
                                                data-tooltip-content={bloodPressError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip15" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Creatine clearance</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: creatineError && '1px solid #e4002b' }} value={creatineClearance} onChange={handleCreatineChange} id="exampleFormControlInput1" placeholder="" />
                                        {creatineError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip16"
                                                data-tooltip-content={creatineError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip16" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Child pugh score</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: childPughError && '1px solid #e4002b' }} value={childPughScore} onChange={handleChildPughChange} id="exampleFormControlInput1" placeholder="" />
                                        {childPughError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip17"
                                                data-tooltip-content={childPughError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip17" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Ferritin</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: ferritinError && '1px solid #e4002b' }} value={ferritin} onChange={handleFerritinChange} id="exampleFormControlInput1" placeholder="" />
                                        {ferritinError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip18"
                                                data-tooltip-content={ferritinError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip18" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>TSH</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: tshError && '1px solid #e4002b' }} value={tsh} onChange={handleTshChange} id="exampleFormControlInput1" placeholder="" />
                                        {tshError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip19"
                                                data-tooltip-content={tshError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip19" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>T4</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: t4Error && '1px solid #e4002b' }} value={t4} onChange={handleT4Change} id="exampleFormControlInput1" placeholder="" />
                                        {t4Error && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip20"
                                                data-tooltip-content={t4Error}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip20" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>T3</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: t3Error && '1px solid #e4002b' }} value={t3} onChange={handleT3Change} id="exampleFormControlInput1" placeholder="" />
                                        {t3Error && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip21"
                                                data-tooltip-content={t3Error}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip21" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Vitamin D</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: vitaminDError && '1px solid #e4002b' }} value={vitaminD} onChange={handleVitaminDChange} id="exampleFormControlInput1" placeholder="" />
                                        {vitaminDError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip22"
                                                data-tooltip-content={vitaminDError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip22" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>Vitamin B12</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: vitaminB12Error && '1px solid #e4002b' }} value={vitaminB12} onChange={handleVitaminB12Change} id="exampleFormControlInput1" placeholder="" />
                                        {vitaminB12Error && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip23"
                                                data-tooltip-content={vitaminB12Error}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip23" />
                                    </div>
                                </div>

                                {/* <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <label className='addPatient-inp-label'>License number</label>
                                    <div className="ap-div">
                                        <input type="text" className="form-control" style={{ border: licenseNoError && '1px solid #e4002b' }} value={licenseNum} onChange={handleLicenseChange} id="exampleFormControlInput1" placeholder="" />
                                        {licenseNoError && (
                                            <i
                                                className="fa-solid fa-circle-info ap-icon"
                                                data-tooltip-id="my-tooltip24"
                                                data-tooltip-content={licenseNoError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip24" />
                                    </div>
                                </div> */}

                            </div>

                            {
                                location?.state?.id ?
                                    <button className='addPatient-btn' disabled={isLoading2} onClick={handleUpdate}>{isLoading2 ? 'Loading...' : 'Update'}</button>
                                    :
                                    <button className='addPatient-btn' disabled={isLoading} onClick={handleSubmit}>{isLoading ? 'Loading...' : 'Add +'}</button>
                            }
                        </div>
                    </>
            }
        </div>
    )
}
