import React, { useState, useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";

import "./MedCondition.css";
import { useStateContext } from "../../../context/AppContext";

export default function MedCondition() {
  const navigate = useNavigate();
  const { userToken } = useStateContext();
  const [data, setdata] = useState([]);

  const [pageLoading, setpageLoading] = useState(true);

  const handleRowClick = (row) => {
    const filter = data.filter((t) => t._id === row.Id);
    navigate("/admin/guidelines", { state: { elem: filter[0] } });
  };

  const addDataAPI = data?.map((elem, i) => {
    return {
      Id: elem?._id,
      Name: (
        <div
          onClick={() =>
            navigate("/admin/guidelines", { state: { elem: elem } })
          }
        >
          {elem?.MedicalCondition?.charAt(0).toUpperCase()}
          {elem?.MedicalCondition?.slice(1)}
        </div>
      ),
      action: (
        <div className="d-flex gap-3">
          <span>
            <i
              style={{ cursor: "pointer" }}
              onClick={() =>
                navigate("/admin/add-medcon", { state: { elem: elem } })
              }
              className="fa-solid fa-pen-to-square fs-4 text-success"
            ></i>
          </span>{" "}
          <span>
            <i
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(elem)}
              className="fa-regular fa-trash-can fs-4 text-danger"
            ></i>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      // center: true,
    },
  ];

  const handleDelete = (elem) => {
    toast(
      <div className="custom-toast">
        <p className="message">
          Are you sure you want to delete this document?
        </p>
        <div className="button-container-toast">
          <button
            className="button-toast yes"
            onClick={() => {
              toast.dismiss();
              submitDelete(elem);
            }}
          >
            Yes
          </button>
          <button
            className="button-toast no"
            onClick={() => {
              toast.dismiss();
            }}
          >
            No
          </button>
        </div>
      </div>
    );
  };

  const submitDelete = (elem) => {
    const formData = { medicalCondition: elem.MedicalCondition };
    axios
      .post(`${process.env.REACT_APP_AI_URL}/delete_guideline`, formData, {
        headers: { token: userToken },
      })
      .then((res) => {
        axios
          .delete(
            `${process.env.REACT_APP_API_URL}/medCondition/delete/${elem._id}`,
            { headers: { token: userToken } }
          )
          .then((response) => {
            axios
              .delete(
                `${process.env.REACT_APP_API_URL}/documents/delete-Alldocs/${elem._id}`,
                { headers: { token: userToken } }
              )
              .then((response) => {
                axios
                  .delete(
                    `${process.env.REACT_APP_API_URL}/monographs/delete-Alldocs/${elem._id}`,
                    { headers: { token: userToken } }
                  )
                  .then((response) => {
                    const newData = data.filter((t) => t._id !== elem._id);
                    setdata(newData);
                    toast.success(res.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/medCondition/get`, {
        headers: { token: userToken },
      })
      .then((res) => {
        setdata(res.data);
        setpageLoading(false);
      })
      .catch((error) => console.log(error));
  }, [userToken]);

  return (
    <div className="medcon-main">
      {pageLoading ? (
        <div
          className="addPatient-pageLoader"
          style={{ backgroundColor: "#F5F6F8" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <div className="medcon-head">
            <h3 className="medcon-title">Guidelines</h3>
            <Link to="/admin/add-medcon" className="medCon-addBtn shadow-sm">
              <i className="fa-solid fa-plus"></i> Add Medical Condition
            </Link>
          </div>
          <div className="medCon-table">
            <DataTable
              noHeader
              pagination
              paginationServer
              columns={addColumns}
              data={addDataAPI}
              onRowClicked={handleRowClick}
              customStyles={{
                cells: {
                  style: {
                    fontWeight: "600",
                  },
                },
              }}
              className="custom-datatable2"
            />
          </div>
        </>
      )}
    </div>
  );
}
