import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { scroller } from 'react-scroll'
import { useNavigate } from 'react-router-dom';

import './Navbar.css'
import { useStateContext } from '../../context/AppContext';

export default function Navbar() {
    const navigate = useNavigate();
    const { isAuthenticated, isAdmin } = useStateContext()
    const [activebtn, setactivebtn] = useState(false)
    const navbarRef = useRef(null);

    const path = window.location.pathname

    const handleClick = (e) => {
        if (path !== '/') { navigate('/', { state: { dataObj: e } }) }
        if (e === 'home') { scroller.scrollTo("/home", { duration: 200, smooth: true, offset: -100 }) }
        if (e === 'aboutus') { scroller.scrollTo("/aboutus", { duration: 200, smooth: true, offset: -100 }) }
        if (e === 'ourteam') { scroller.scrollTo("/ourteam", { duration: 200, smooth: true, offset: -100 }) }
        if (e === 'testimonials') { scroller.scrollTo("/testimonials", { duration: 200, smooth: true, offset: -100 }) }
        if (e === 'faq') { scroller.scrollTo("/faq", { duration: 200, smooth: true, offset: -100 }) }
    }

    useEffect(() => {
        const navbar = navbarRef.current;
        if (navbar) {
            window.addEventListener('scroll', function () {
                const scrollPosition = window.scrollY;
                if (scrollPosition >= 20) {
                    navbar.classList.add('scrolled');
                } else {
                    navbar.classList.remove('scrolled');
                }
            });
        }
    }, []);

    return (
        <nav className={`navbar navbar-expand-lg bg-body-tertiary custom-navbar ${activebtn && 'nav-bg'}`} ref={navbarRef}>
            <div className="container">
                <a className="navbar-brand" href="/">
                    <h1 className='nav-logo'><span style={{ color: "#3195D8" }}>ECHO AI</span><i className="fa-solid fa-plus fs-4 text-danger"></i></h1>
                </a>
                <button
                    className={`navbar-toggler ${activebtn && 'nav-toggler-bg'}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                    onClick={() => setactivebtn(!activebtn)}
                >
                    <i className={`fa-solid fa-bars nav-toggler-icon ${activebtn && 'nav-toggler-icon-clr'}`}></i>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <div className="nav-link active" aria-current="page" onClick={() => handleClick("home")}>
                                Home <i className="fa-solid fa-plus nav-icon"></i>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" onClick={() => handleClick("aboutus")}>
                                About us <i className="fa-solid fa-plus nav-icon"></i>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" onClick={() => handleClick("ourteam")}>
                                Our Team <i className="fa-solid fa-plus nav-icon"></i>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" onClick={() => handleClick("testimonials")}>
                                Testimonials <i className="fa-solid fa-plus nav-icon"></i>
                            </div>
                        </li>
                        <li className="nav-item">
                            <div className="nav-link" onClick={() => handleClick("faq")}>
                                FAQ <i className="fa-solid fa-plus nav-icon"></i>
                            </div>
                        </li>
                    </ul>
                    <div className="d-flex">
                        {
                            isAuthenticated ?
                                <Link to={isAdmin ? '/admin' : '/dashboard'} className="nav-btn">
                                    Dashboard
                                </Link>
                                :
                                <Link to='/login' className="nav-btn">
                                    Login
                                </Link>
                        }
                    </div>
                </div>
            </div>
        </nav>
    )
}
