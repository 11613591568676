import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useStateContext } from '../../../context/AppContext';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { Tooltip } from 'react-tooltip';
import DataTable from 'react-data-table-component';

import './PatientAction.css'
import Modal from '../../../components/Modal/Modal';

export default function PatientActions() {
    const navigate = useNavigate();
    const { slug } = useParams();
    const { userToken } = useStateContext();

    const [id, setid] = useState('')
    const [dob, setdob] = useState('')
    const [data, setdata] = useState([])
    const [isModal, setisModal] = useState(false)
    const [patientData, setpatientData] = useState("")

    const [idError, setidError] = useState('')
    const [dobError, setdobError] = useState('')

    const [multiPatients, setmultiPatients] = useState([])

    const handleClick = () => {
        if (!id && !dob) {
            setidError("Please enter patient id"); setdobError('Please select patient dob')
            return
        }
        if (dob) { setidError('') }
        if (id) { setdobError("") }

        let filter = []

        if (id && dob) {
            filter = data?.filter((t) => t.patientNo === id && t.age === dob)
        } else {
            filter = data?.filter((t) => t.patientNo === id || t.age === dob)
        }

        if (filter.length === 0) {
            if (id && dob) {
                setidError('Patient not found!'); setdobError('Patient not found!')
                return
            }
            if (dob) { setdobError('Patient not found!') }
            if (id) { setidError("Patient not found!") }
        }

        if (filter.length === 1) {
            setmultiPatients([])
            if (slug === 'get-recommendation') {
                navigate(`/dashboard/patient-details/${filter[0]?.patientNo}?purpose=${encodeURIComponent("get-recommendation")}`)
            }
            if (slug === 'view-recommendation') {
                navigate(`/dashboard/pre-responses/${'view-recommendations'}`, { state: { id: filter[0]._id } })
            }
            if (slug === 'edit-info') {
                navigate('/dashboard/add-patient', { state: { id: filter[0]?.patientNo } })
            }
            if (slug === 'delete-info') {
                if (filter.length !== 0) {
                    setpatientData(filter[0])
                    setisModal(true)
                    setid('')
                }
            }
        } else {
            setmultiPatients(filter)
        }
    }

    const handleClick2 = (elem) => {
        if (slug === 'get-recommendation') {
            navigate(`/dashboard/patient-details/${elem?.patientNo}?purpose=${encodeURIComponent("get-recommendation")}`)
        }
        if (slug === 'view-recommendation') {
            navigate(`/dashboard/pre-responses/${'view-recommendations'}`, { state: { id: elem?._id } })
        }
        if (slug === 'edit-info') {
            navigate('/dashboard/add-patient', { state: { id: elem?.patientNo } })
        }
        if (slug === 'delete-info') {
            setpatientData(elem)
            setisModal(true)
            setid('')
            setdob('')
            setmultiPatients([])
        }
    }

    const handleIdChange = (e) => {
        setid(parseInt(e.target.value))
        if (!e.target.value) {
            return setidError('Please enter patient id')
        }
        setidError('')
    }

    const handledobChange = (e) => {
        setdob(e.target.value)
        if (!e.target.value) {
            return setdobError('Please select the patient dob')
        }
        setdobError('')
    }

    const addDataAPI = multiPatients?.map((elem, i) => {
        return {
            Id: elem.patientNo,
            Name: `${elem.name}`,
            dob: `${elem.age}`,
            action: (
                <div>
                    <span>
                        <button target='_blank'
                            className="btn btn-danger btn-xs"
                            style={{ width: 60, fontSize: 14, padding: 4, textDecoration: "none" }}
                            onClick={() => handleClick2(elem)}
                        >
                            Next
                        </button>
                    </span>
                </div>
            ),
        };
    });

    const addColumns = [
        {
            name: "ID",
            selector: (row) => row.Id,
            sortable: true,
            center: true,
        },
        {
            name: "Name",
            selector: (row) => row.Name,
            sortable: true,
            center: true,
        },
        {
            name: "Dob",
            selector: (row) => row.dob,
            sortable: true,
            center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            center: true,
        },
    ];

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/patient/get-date`, { headers: { token: userToken } })
            .then((res) => {
                setdata(res?.data)
            })
            .catch((error) => console.log(error));
    }, [userToken])
    return (
        <>
            <i className="fa-solid fa-arrow-left pa-backBtn" onClick={() => navigate(-1)}></i>
            <div className='pa-main'>
                <h2>Enter Patient Id/Date Of Birth</h2>
                <div className='pa-body'>
                    <div style={{ position: 'relative' }}>
                        <input className='form-control pa-input' style={{ border: idError && '1px solid #DC3545' }} value={id} onChange={handleIdChange} type='number' />
                        {idError && (
                            <i
                                className="fa-solid fa-circle-info pa-error-icon"
                                data-tooltip-id="my-tooltip1"
                                data-tooltip-content={idError}
                                data-tooltip-place="top"
                            ></i>
                        )}
                        <Tooltip id="my-tooltip1" />
                    </div>
                    <div className='pa-input text-center'>OR</div>
                    <div style={{ position: 'relative' }}>
                        <input className='form-control pa-input' style={{ border: dobError && '1px solid #DC3545' }} value={dob} onChange={handledobChange} type='date' />
                        {dobError && (
                            <i
                                className="fa-solid fa-circle-info pa-error-icon"
                                data-tooltip-id="my-tooltip2"
                                data-tooltip-content={dobError}
                                data-tooltip-place="top"
                            ></i>
                        )}
                        <Tooltip id="my-tooltip2" />
                    </div>
                </div>
                <button className='btn btn-danger mt-2 pa-input' onClick={handleClick}>Next</button>

                {multiPatients?.length !== 0 && (
                    <div className="border mt-5">
                        <DataTable
                            noHeader
                            pagination
                            paginationServer
                            columns={addColumns}
                            data={addDataAPI}
                            customStyles={{
                                rows: {
                                    style: {
                                        cursor: 'pointer',
                                    },
                                },
                                cells: {
                                    style: {
                                        fontWeight: '600',
                                        color: '#0B2643',
                                    },
                                },
                            }}

                            className="custom-datatable"
                        />
                    </div>
                )}

                {isModal && <Modal setisModal={setisModal} patientData={patientData} data={data} setdata={setdata} />}
            </div>
        </>
    )
}
