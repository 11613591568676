import React from 'react'
import { Link } from 'react-router-dom'
import { scroller } from 'react-scroll'

import './Footer.css'

export default function Footer() {

    const handleClick = (e) => {
        if (e === 'home') { scroller.scrollTo("/home", { duration: 200, smooth: true, offset: -100 }) }
        if (e === 'aboutus') { scroller.scrollTo("/aboutus", { duration: 200, smooth: true, offset: -100 }) }
        if (e === 'ourteam') { scroller.scrollTo("/ourteam", { duration: 200, smooth: true, offset: -100 }) }
        if (e === 'testimonials') { scroller.scrollTo("/testimonials", { duration: 200, smooth: true, offset: -100 }) }
        if (e === 'faq') { scroller.scrollTo("/faq", { duration: 200, smooth: true, offset: -100 }) }
    }
    return (
        <div className='footer-main'>
            <div className='container'>
                <div className='row footer-boder'>
                    <div className='col-lg-3 col-md-6'>
                        <Link to="/" style={{ textDecoration: "none" }}>
                            <h1 className='footer-logo'><span style={{ color: "#3195D8" }}>ECHO AI</span><i className="fa-solid fa-plus fs-4 text-danger"></i></h1>
                        </Link>
                        <p className='footer-text'>Elevating healthcare with innovation and personalized solutions. Your well-being is our commitment at ECHO AI.</p>
                        {/* <div className='footer-icon-div'>
                            <i className="fa-brands fa-facebook-f footer-icon"></i>
                            <i className="fa-brands fa-instagram footer-icon"></i>
                            <i className="fa-brands fa-twitter footer-icon"></i>
                            <i className="fa-brands fa-linkedin-in footer-icon"></i>
                        </div> */}
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <p className='footer-title'>Contact</p>
                        <div className='footer-info'>
                            <div><i className="fa-solid fa-location-dot footer-icon2"></i><span className='footer-info-text'>No: 58 A, East Madison Street</span></div>
                            <div><i className="fa-solid fa-envelope footer-icon2"></i><span className='footer-info-text'>No: 58 A, East Madison Street</span></div>
                            <div><i className="fa-solid fa-phone footer-icon2"></i><span className='footer-info-text'>No: 58 A, East Madison Street</span></div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <p className='footer-title'>Links</p>
                        <p className='footer-link-text' onClick={() => handleClick("home")}>Home</p>
                        <p className='footer-link-text' onClick={() => handleClick("aboutus")}>About Us</p>
                        <p className='footer-link-text' onClick={() => handleClick("ourteam")}>Our Team</p>
                        <p className='footer-link-text' onClick={() => handleClick("testimonials")}>Testimonials</p>
                        <p className='footer-link-text' onClick={() => handleClick("faq")}>FAQ</p>
                    </div>
                    <div className='col-lg-3 col-md-6'>
                        <p className='footer-title'>Contact</p>
                        <p className='footer-text'>Have questions or need assistance? Reach out to our dedicated support team. Your health and satisfaction are our top priorities.</p>
                        {/* <div className='footer-input-div'>
                            <input className='footer-input' placeholder='Your E-mail' /> <i className="fa-regular fa-paper-plane send-icon"></i>
                        </div> */}
                    </div>
                </div>
                <div className="footer-copywrite">© 2023 <Link>Satesfytech.</Link> All rights reserved</div>
            </div>
        </div>
    )
}
