import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import './ApexChart.css';

const ApexChart = ({ Users }) => {
  const [activebtn, setactivebtn] = useState('month')
  const [chartData, setChartData] = useState(getDailyChartData());
  const [isLoading, setisLoading] = useState(true)

  useEffect(() => {
    setChartData(getDailyChartData());
    setisLoading(false)
  }, [Users]);

  function getAllChartData() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    // Get the earliest registration date among all users
    const earliestDate = Users.reduce((earliest, user) => {
      const userDate = new Date(user.createdAt);
      return userDate < earliest ? userDate : earliest;
    }, currentDate);

    const startMonth = earliestDate.getMonth() + 1;
    const startYear = earliestDate.getFullYear();

    // Get the range of months from the earliest registration to the current month
    const monthsRange = getMonthsRange(startYear, startMonth, currentYear, currentMonth);

    // Initialize user registration data for all months with zero counts
    const userRegistrationData = monthsRange.reduce((acc, month) => {
      acc[month] = 0;
      return acc;
    }, {});

    // Populate user registration data with actual counts
    Users.forEach(user => {
      const userDate = new Date(user.createdAt);
      const formattedDate = `${getMonthName(userDate.getMonth() + 1)} ${userDate.getFullYear()}`;
      userRegistrationData[formattedDate]++;
    });

    return {
      options: {
        chart: {
          id: 'user-registration-chart',
        },
        xaxis: {
          categories: monthsRange,
        },
        dataLabels: {
          enabled: false,
        },
      },
      series: [
        {
          name: 'New Users',
          data: monthsRange.map(month => userRegistrationData[month] || 0),
        },
      ],
    };
  }

  function getMonthsRange(startYear, startMonth, endYear, endMonth) {
    const months = [];
    for (let year = startYear; year <= endYear; year++) {
      const start = year === startYear ? startMonth : 1;
      const end = year === endYear ? endMonth : 12;

      for (let month = start; month <= end; month++) {
        months.push(`${getMonthName(month)} ${year}`);
      }
    }

    return months;
  }



  function getDailyChartData() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
    const currentYear = currentDate.getFullYear();
    const daysInMonth = new Date(currentYear, currentMonth, 0).getDate();

    const userRegistrationData = Users?.reduce((acc, user) => {
      const userDate = new Date(user.createdAt);
      const userMonth = userDate.getMonth() + 1; // Months are 0-indexed
      const userYear = userDate.getFullYear();
      const userDay = userDate.getDate();

      if (userMonth === currentMonth && userYear === currentYear) {
        const formattedDate = `${userDay}`;
        acc[formattedDate] = (acc[formattedDate] || 0) + 1;
      }

      return acc;
    }, {});

    // Generate an array of days in the current month
    const daysArray = Array.from({ length: daysInMonth }, (_, index) => `${index + 1}`);

    return {
      options: {
        chart: {
          id: 'user-registration-chart',
        },
        xaxis: {
          categories: daysArray,
        },
        dataLabels: {
          enabled: false,
        },
      },
      series: [
        {
          name: 'New Users',
          data: daysArray.map(date => userRegistrationData[date] || 0),
        },
      ],
    };
  }


  const handleDayButtonClick = () => {
    setactivebtn('month')
    setChartData(getDailyChartData());
  };
  const handleAllButtonClick = () => {
    const hasPastMonthData = Users?.some(patient => {
      const createdAtDate = new Date(patient.createdAt);
      const currentMonth = new Date();
      return (
        createdAtDate.getFullYear() < currentMonth.getFullYear() ||
        (createdAtDate.getFullYear() === currentMonth.getFullYear() &&
          createdAtDate.getMonth() < currentMonth.getMonth())
      );
    });

    setactivebtn('all')
    if (hasPastMonthData) {
      setChartData(getAllChartData());
    } else {
      setChartData(getDailyChartData());
    }
  };

  function handleMonthButtonClick() {
    setactivebtn('sixMonth')
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const monthsArray = Array.from({ length: 6 }, (_, index) => {
      let year
      if (currentMonth - index == 0) { year = currentYear - 1 }
      else if (currentMonth - index == -1) { year = currentYear - 1 }
      else if (currentMonth - index == -2) { year = currentYear - 1 }
      else if (currentMonth - index == -3) { year = currentYear - 1 }
      else if (currentMonth - index == -4) { year = currentYear - 1 }
      else if (currentMonth - index == -5) { year = currentYear - 1 }
      else if (currentMonth - index == -6) { year = currentYear - 1 }
      else if (currentMonth - index == -7) { year = currentYear - 1 }
      else if (currentMonth - index == -8) { year = currentYear - 1 }
      else if (currentMonth - index == -9) { year = currentYear - 1 }
      else if (currentMonth - index == -10) { year = currentYear - 1 }
      else if (currentMonth - index == -11) { year = currentYear - 1 }
      else { year = currentYear }
      const month = (currentMonth - index + 12) % 12 || 12;
      return `${month},${year}`;
    }).reverse();

    // const monthsArray = Array.from({ length: 6 }, (_, index) => {
    //   const month = (currentMonth - index + 12) % 12 || 12;
    //   return `${month},${currentYear}`;
    // }).reverse();

    const userRegistrationData = Users?.reduce((acc, user) => {
      const userDate = new Date(user.createdAt);
      const userMonth = userDate.getMonth() + 1;
      const userYear = userDate.getFullYear();
      const formattedDate = `${userMonth},${userYear}`;

      if (monthsArray.includes(formattedDate)) {
        acc[formattedDate] = (acc[formattedDate] || 0) + 1;
      }

      return acc;
    }, {});

    const categories = monthsArray.map(month => {
      const [monthNumber, year] = month.split(',');
      return `${getMonthName(monthNumber)} ${year}`;
    });

    const seriesData = monthsArray.map(month => userRegistrationData[month] || 0);

    setChartData({
      options: {
        chart: {
          id: 'user-registration-chart',
        },
        xaxis: {
          categories,
        },
        dataLabels: {
          enabled: false,
        },
      },
      series: [
        {
          name: 'New Users',
          data: seriesData,
        },
      ],
    });
  }

  function getMonthName(monthNumber) {
    const months = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return months[monthNumber - 1];
  }

  const handleYearButtonClick = () => {
    setactivebtn('year');
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    const monthsArray = Array.from({ length: 12 }, (_, index) => {
      let year
      if (currentMonth - index == 0) { year = currentYear - 1 }
      else if (currentMonth - index == -1) { year = currentYear - 1 }
      else if (currentMonth - index == -2) { year = currentYear - 1 }
      else if (currentMonth - index == -3) { year = currentYear - 1 }
      else if (currentMonth - index == -4) { year = currentYear - 1 }
      else if (currentMonth - index == -5) { year = currentYear - 1 }
      else if (currentMonth - index == -6) { year = currentYear - 1 }
      else if (currentMonth - index == -7) { year = currentYear - 1 }
      else if (currentMonth - index == -8) { year = currentYear - 1 }
      else if (currentMonth - index == -9) { year = currentYear - 1 }
      else if (currentMonth - index == -10) { year = currentYear - 1 }
      else if (currentMonth - index == -11) { year = currentYear - 1 }
      else { year = currentYear }
      const month = (currentMonth - index + 12) % 12 || 12;
      return `${month},${year}`;
    }).reverse();

    const userRegistrationData = Users?.reduce((acc, user) => {
      const userDate = new Date(user.createdAt);
      const userMonth = userDate.getMonth() + 1;
      const userYear = userDate.getFullYear();
      const formattedDate = `${userMonth},${userYear}`;

      if (monthsArray.includes(formattedDate)) {
        acc[formattedDate] = (acc[formattedDate] || 0) + 1;
      }

      return acc;
    }, {});

    const categories = monthsArray.map(month => {
      const [monthNumber, year] = month.split(',');
      return `${getMonthName(monthNumber)} ${year}`;
    });

    const seriesData = monthsArray.map(month => userRegistrationData[month] || 0);

    setChartData({
      options: {
        chart: {
          id: 'user-registration-chart',
        },
        xaxis: {
          categories,
        },
        dataLabels: {
          enabled: false,
        },
      },
      series: [
        {
          name: 'New Users',
          data: seriesData,
        },
      ],
    });
  };
  return (
    <>
      {
        isLoading ?
          <div className='mt-4 d-flex justify-content-center'>
            <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          :
          <div id="chart">
            <div className="toolbar">
              <button onClick={handleDayButtonClick} className={`${activebtn == 'month' ? 'active' : ''}`}>1M</button>
              <button onClick={handleMonthButtonClick} className={`${activebtn == 'sixMonth' ? 'active' : ''}`}>6M</button>
              <button onClick={handleYearButtonClick} className={`${activebtn == 'year' ? 'active' : ''}`}>Year</button>
              <button onClick={handleAllButtonClick} className={`${activebtn == 'all' ? 'active' : ''}`}>All</button>
            </div>

            <div id="chart-timeline">
              <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={350} />
            </div>
          </div>
      }
    </>
  );
};

export default ApexChart;
