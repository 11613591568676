import React, { useState, useRef, useEffect } from "react";
import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import DataTable from "react-data-table-component";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject,
} from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import { Element, scroller } from "react-scroll";
import { useLocation } from "react-router-dom";

import "./Guidelines.css";
import { storage } from "../../../config/firebase";
import { useStateContext } from "../../../context/AppContext";

export default function Guidelines() {
  const location = useLocation();
  const { userToken } = useStateContext();
  const [data, setdata] = useState([]);
  const [docUrls, setdocUrls] = useState([]);

  const [data2, setdata2] = useState([]);

  const [files, setFiles] = useState([]);
  const dropzoneKey = useRef(0);
  const [files2, setFiles2] = useState([]);
  const dropzoneKey2 = useRef(0);

  const [isLoading, setisLoading] = useState(false);
  const [isLoading2, setisLoading2] = useState(false);
  const [pageLoader, setpageLoader] = useState(true);

  const [delDocId, setdelDocId] = useState("");
  const [delDocId2, setdelDocId2] = useState("");

  const addDataAPI = data?.map((elem, i) => {
    return {
      Name: `${elem.name}`,
      action: (
        <div>
          <span>
            <a
              href={elem.url}
              target="_blank"
              rel="noreferrer"
              className="btn btn-success btn-xs"
              style={{
                width: 60,
                fontSize: 14,
                padding: 4,
                textDecoration: "none",
              }}
            >
              Open
            </a>
          </span>{" "}
          <span>
            <button
              className="btn btn-danger btn-xs"
              style={{ width: 60, fontSize: 14, padding: 4 }}
              onClick={() => handleDelete(elem)}
              disabled={isLoading && delDocId}
            >
              {isLoading && delDocId === elem._id ? "Loading..." : "Delete"}
            </button>
          </span>
        </div>
      ),
    };
  });

  const addColumns = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      // center: true,
    },
  ];

  const addDataAPI2 = data2?.map((elem, i) => {
    return {
      Name: `${elem.name}`,
      action: (
        <div>
          <span>
            <a
              href={elem.url}
              target="_blank"
              rel="noreferrer"
              className="btn btn-success btn-xs"
              style={{
                width: 60,
                fontSize: 14,
                padding: 4,
                textDecoration: "none",
              }}
            >
              Open
            </a>
          </span>{" "}
          <span>
            <button
              className="btn btn-danger btn-xs"
              style={{ width: 60, fontSize: 14, padding: 4 }}
              onClick={() => handleDelete2(elem)}
              disabled={isLoading2 && delDocId2}
            >
              {isLoading2 && delDocId2 === elem._id ? "Loading..." : "Delete"}
            </button>
          </span>
        </div>
      ),
    };
  });

  const addColumns2 = [
    {
      name: "Name",
      selector: (row) => row.Name,
      sortable: true,
      // center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      // center: true,
    },
  ];

  const handleChangeStatus = ({ file, meta, remove }, status) => {
    if (status === "done") {
      setFiles((prevFiles) => [...prevFiles, { file, meta }]);
    }

    if (status === "removed") {
      setFiles((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile.file.name !== file.name)
      );
    }
  };

  const handleChangeStatus2 = ({ file, meta, remove }, status) => {
    if (status === "done") {
      setFiles2((prevFiles) => [...prevFiles, { file, meta }]);
    }

    if (status === "removed") {
      setFiles2((prevFiles) =>
        prevFiles.filter((prevFile) => prevFile.file.name !== file.name)
      );
    }
  };

  const uploadDocument = (image) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `documents/${image.file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, image.file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {},
        (error) => {
          console.log(error);
          reject(error);
        },
        async () => {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

          resolve(downloadUrl);
        }
      );
    });
  };

  const onSubmit = async () => {
    if (!location?.state?.elem) {
      return toast.error("Please Select The Medical Condition");
    }

    if (files.length === 0) {
      return toast.error("Please Select The Document");
    }
    let docs = [];
    let DocUrl = [];
    try {
      setisLoading(true);

      console.log(files, "files");

      if (files.length > 1) {
        let uploadPromises = files.map((file) => {
          const storageReferenceName = uuidv4();
          return uploadDocument(file, storageReferenceName).then((url) => {
            const doc = {
              name: file.file.name,
              url: url,
              storageReferenceName: storageReferenceName,
              medConId: location?.state?.elem?._id,
            };
            docs.push(doc);
            DocUrl.push(url);
          });
        });

        await Promise.all(uploadPromises);
      } else {
        const storageReferenceName = uuidv4();
        const url = await uploadDocument(files[0], storageReferenceName);

        const doc = {
          name: files[0].file.name,
          url: url,
          storageReferenceName: storageReferenceName,
          medConId: location?.state?.elem?._id,
        };

        docs.push(doc);
        DocUrl.push(url);
      }

      let random = Math.floor(Math.random() * 100000);
      let Obj = {
        medicalCondition: location?.state?.elem?.MedicalCondition,
        documentsURL: Object.fromEntries(
          docs.map((item, index) => [
            `${item.name.split(".")[0]}-${random + index + 1}`,
            item.url,
          ])
        ),
      };

      const createGuidelineResponse = await axios.post(
        `${process.env.REACT_APP_AI_URL}/${
          data.length > 0 ? "add_guideline_document" : "create_guideline"
        }`,
        Obj,
        { headers: { token: userToken } }
      );

      if (createGuidelineResponse.data) {
        await Promise.all(
          docs.map((item, index) =>
            axios.post(
              `${process.env.REACT_APP_API_URL}/documents/save`,
              {
                ...item,
                name: `${item.name.split(".")[0]}-${random + index + 1}`,
              },
              { headers: { token: userToken } }
            )
          )
        );

        axios
          .get(`${process.env.REACT_APP_API_URL}/documents/get`, {
            headers: { token: userToken },
          })
          .then((res) => {
            const filter = res.data.filter(
              (t) => t.medConId === location?.state?.elem?._id
            );
            const urls = filter.map((item) => item.url);
            setdocUrls(urls);
            console.log(filter, "filter", res.data, "res.data");
            setdata(filter);

            setdelDocId("");

            scroller.scrollTo("/doc-table", {
              duration: 200,
              smooth: true,
              offset: -100,
            });
            dropzoneKey.current += 1;
            setFiles([]);
            toast.success(createGuidelineResponse.data);
          })
          .catch((error) => console.log(error));
      }
    } catch (error) {
      toast.error("Something went wrong!");
      if (DocUrl.length > 0) {
        DocUrl.map((url) => deleteDocument(url));
      }

      setisLoading(false);
    } finally {
      setisLoading(false);
    }
  };

  const onSubmit2 = async () => {
    if (!location?.state?.elem) {
      return toast.error("Please Select The Medical Condition");
    }

    if (files2.length === 0) {
      return toast.error("Please Select The Mongograph Document");
    }
    try {
      setisLoading2(true);

      let docs = [];

      for (const file of files2) {
        const storageReferenceName = uuidv4();
        const url = await uploadDocument(file, storageReferenceName);
        const randomNum =
          Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;

        const doc = {
          name: file.file.name + "-" + randomNum,
          url: url,
          storageReferenceName: storageReferenceName,
          medConId: location?.state?.elem?._id,
        };

        docs.push(doc);
      }

      let Obj = {
        medicalCondition: location?.state?.elem?.MedicalCondition,
        documentsURL: Object.fromEntries(docs.map((t) => [t.name, t.url])),
      };

      const createMonographResponse = await axios.post(
        `${process.env.REACT_APP_AI_URL}/${
          data2.length === 0 ? "create_monograph" : "add_monograph_document"
        }`,
        Obj,
        { headers: { token: userToken } }
      );

      if (createMonographResponse.data) {
        await Promise.all(
          docs.map((item) =>
            axios.post(
              `${process.env.REACT_APP_API_URL}/monographs/save`,
              item,
              { headers: { token: userToken } }
            )
          )
        );

        axios
          .get(`${process.env.REACT_APP_API_URL}/monographs/get`, {
            headers: { token: userToken },
          })
          .then((res) => {
            const filter = res.data.filter(
              (t) => t.medConId === location?.state?.elem?._id
            );
            setdata2(filter);

            scroller.scrollTo("/doc-table2", {
              duration: 200,
              smooth: true,
              offset: -100,
            });
            dropzoneKey2.current += 1;
            setFiles2([]);
            toast.success(createMonographResponse.data);
          })
          .catch((error) => console.log(error));
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setisLoading2(false);
    }
  };

  const handleDelete2 = (elem) => {
    toast(
      <div className="custom-toast">
        <p className="message">
          Are you sure you want to delete this monograph document?
        </p>
        <div className="button-container-toast">
          <button
            className="button-toast yes"
            onClick={async () => {
              toast.dismiss();
              setdelDocId2(elem._id);

              setisLoading2(true);
              let name = elem?.name + ".txt";
              try {
                let Obj = {
                  medicalCondition: location?.state?.elem?.MedicalCondition,
                  filename: name,
                };
                const createMonographResponse = await axios.post(
                  `${process.env.REACT_APP_AI_URL}/delete_monograph_document`,
                  Obj,
                  { headers: { token: userToken } }
                );
                if (createMonographResponse.data) {
                  axios
                    .delete(
                      `${process.env.REACT_APP_API_URL}/monographs/delete/${elem._id}`,
                      { headers: { token: userToken } }
                    )
                    .then((res) => {
                      const newData = data2.filter((t) => t._id !== elem._id);
                      setdata2(newData);

                      toast.success(createMonographResponse.data);

                      deleteDocument(elem.url)
                        .then(() => {
                          console.log("File deleted successfully");
                          setisLoading2(false);
                        })
                        .catch((error) => {
                          console.error("Error deleting file", error);
                        });
                    })

                    .catch((err) => {
                      console.log(err);
                      setisLoading2(false);
                    });
                }
              } catch (error) {
                toast.error("Something went wrong!");
                setisLoading2(false);
                setdelDocId2("");
              } finally {
                setisLoading2(false);
              }
            }}
          >
            Yes
          </button>
          <button
            className="button-toast no"
            onClick={() => {
              toast.dismiss();
            }}
          >
            No
          </button>
        </div>
      </div>
    );
  };

  const deleteDocument = (filePath) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, filePath);

      deleteObject(storageRef)
        .then(() => {
          console.log("File deleted successfully");
          resolve();
        })
        .catch((error) => {
          console.error("Error deleting file", error);
          reject(error);
        });
    });
  };

  const handleDelete = (elem) => {
    try {
      toast(
        <div className="custom-toast">
          <p className="message">
            Are you sure you want to delete this document?
          </p>
          <div className="button-container-toast">
            <button
              className="button-toast yes"
              onClick={async () => {
                setdelDocId(elem._id);
                toast.dismiss();
                setisLoading(true);

                try {
                  const newDataUrls = docUrls.filter((url) => url !== elem.url);

                  let Obj = {
                    medicalCondition: location?.state?.elem?.MedicalCondition,
                    filename: `${elem.name}.txt`,
                  };

                  const deleteGuidelineDocument = await axios.post(
                    `${process.env.REACT_APP_AI_URL}/delete_guideline_document`,
                    Obj
                  );

                  if (deleteGuidelineDocument.status === 200) {
                    const response = await axios.delete(
                      `${process.env.REACT_APP_API_URL}/documents/delete/${elem._id}`,
                      { headers: { token: userToken } }
                    );

                    if (response.status === 200) {
                      const newData = data.filter((t) => t._id !== elem._id);
                      setdata(newData);
                      setdocUrls(newDataUrls);
                      setdelDocId("");
                      // toast.success(deleteGuidelineDocument.data);
                      deleteDocument(elem.url)
                        .then(() => {
                          console.log("File deleted successfully");
                          setisLoading(false);
                        })
                        .catch((error) => {
                          console.error("Error deleting file", error);
                          setisLoading(false);
                        });

                      toast.success("Document deleted successfully");
                      scroller.scrollTo("/doc-table", {
                        duration: 200,
                        smooth: true,
                        offset: -100,
                      });
                    }
                  }
                } catch (error) {
                  toast.error("Something went wrong");
                  setisLoading(false);
                } finally {
                  setisLoading(false);
                }
              }}
            >
              Yes
            </button>
            <button
              className="button-toast no"
              onClick={() => {
                toast.dismiss();
              }}
            >
              No
            </button>
          </div>
        </div>
      );
    } catch (error) {
      toast.error("Something went wrong!");
      setisLoading(false);
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/documents/get`, {
        headers: { token: userToken },
      })
      .then((res) => {
        const filter = res.data.filter(
          (t) => t.medConId === location?.state?.elem?._id
        );
        const urls = filter.map((item) => item.url);

        setdocUrls(urls);
        setdata(filter);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${process.env.REACT_APP_API_URL}/monographs/get`, {
        headers: { token: userToken },
      })
      .then((res) => {
        const filter = res.data.filter(
          (t) => t.medConId === location?.state?.elem?._id
        );
        setdata2(filter);
        setpageLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setpageLoader(false);
      });
  }, [location?.state?.elem, userToken]);

  return (
    <div className="guidelines-main">
      <h3 className="guidelines-title">Add Guidelines</h3>
      <div className="guidelines-box">
        <Dropzone
          key={dropzoneKey.current}
          onChangeStatus={handleChangeStatus}
          inputContent="Upload"
          multiple={true}
          canCancel={true}
          accept=".txt"
          styles={{
            dropzone: { width: "100%", minHeight: 50, padding: "1rem" },
          }}
        />
        <button
          className="guideline-btn"
          disabled={isLoading}
          onClick={onSubmit}
        >
          {isLoading ? "Loading..." : "Train System"}
        </button>
      </div>

      {pageLoader ? (
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Element name="/doc-table">
          <h3 className="guidelines-title mt-5">Guidelines List</h3>
          <div className="border mb-5">
            <DataTable
              noHeader
              paginationServer
              columns={addColumns}
              data={addDataAPI}
              customStyles={{
                cells: {
                  style: {
                    fontWeight: "600",
                  },
                },
              }}
              className="custom-datatable2"
            />
          </div>
        </Element>
      )}

      <hr />

      <h3 className="guidelines-title mt-5">Add Monograph</h3>
      <div className="guidelines-box">
        <Dropzone
          key={dropzoneKey2.current}
          onChangeStatus={handleChangeStatus2}
          inputContent="Upload"
          multiple={true}
          canCancel={true}
          accept=".txt"
          styles={{
            dropzone: { width: "100%", minHeight: 50, padding: "1rem" },
          }}
        />
        <button
          className="guideline-btn"
          disabled={isLoading2}
          onClick={onSubmit2}
        >
          {isLoading2 ? "Loading..." : "Train System"}
        </button>
      </div>
      {pageLoader ? (
        <div className="mt-5 d-flex justify-content-center align-items-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <Element name="/doc-table2">
          <h3 className="guidelines-title mt-5">Monograph List</h3>
          <div className="border mb-5">
            <DataTable
              noHeader
              pagination
              columns={addColumns2}
              data={addDataAPI2}
              customStyles={{
                cells: {
                  style: {
                    fontWeight: "600",
                  },
                },
              }}
              className="custom-datatable2"
            />
          </div>
        </Element>
      )}
    </div>
  );
}
