export const opioids = [
    "Morphine",
    "Oxycodone",
    "Hydrocodone",
    "Codeine",
    "Fentanyl",
    "Methadone",
    "Suboxone",
    "Kadian",
    "Hydromorphone",
    "Buprenorphine",
    "Codeine Contin",
    "Percocet",
    "Tylenol #1",
    "Tylenol #2",
    "Tylenol #3",
    "Tylenol #4",
    "Tapentadol",
    "Oxymorphone",
    "Oxycodone Contin",
    "Hydromorph Contin",
    "Tramadol",
    "Meperidine",
    "Nalbuphine",
    "Pentazocine",
    "Butorphanol",
]

export const child_pugh_scores = ["", "A", "B", "C"]

export const reduce_dose_values = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50]

export const conversionFactor = {
    "oxycodone": ["morphine", 1.5, 1 / 1.5],
    "hydrocodone": ["morphine", 0.9, 1 / 0.9],
    "codeine": ["morphine", 0.15, 1 / 0.15],
    "methadone": ['morphine', 3.0, 1 / 3.0],
    "suboxone": ['morphine', 4.0, 1 / 4.0],
    "tapentadol": ['morphine', 0.4, 1 / 0.4],
    "tylenol #1": ['morphine', 0.12, 1 / 0.12],
    "kadian": ['morphine', 1.2, 1 / 1.2],
    "codeine contin": ['morphine', 0.3, 1 / 0.3],
    "tylenol #2": ['morphine', 0.15, 1 / 0.15],
    "tylenol #3": ['morphine', 0.18, 1 / 0.18],
    "tylenol #4": ['morphine', 0.24, 1 / 0.24],
    "percocet": ['morphine', 1.5, 1 / 1.5],
    "hydromorphone": ['morphine', 4.0, 1 / 4.0],
    "hydromorph contin": ['morphine', 1.0, 1 / 1.0],
    "fentanyl": ['morphine', 75.0, 1 / 75.0],
    "tramadol": ['morphine', 0.1, 1 / 0.1],
    "meperidine": ['morphine', 0.1, 1 / 0.1],
    "nalbuphine": ['morphine', 0.08, 1 / 0.08],
    "pentazocine": ['morphine', 0.33, 1 / 0.33],
    "butorphanol": ['morphine', 0.17, 1 / 0.17],
    "oxycodone_injectable": ["morphine_injectable", 1.0, 1 / 1.0],
    "hydrocodone_injectable": ["morphine_injectable", 0.6, 1 / 0.6],
    "codeine_injectable": ["morphine_injectable", 0.1, 1 / 0.1],
    "methadone_injectable": ["morphine_injectable", 2.5, 1 / 2.5],
    "suboxone_injectable": ["morphine_injectable", 3.5, 1 / 3.5],
    "tapentadol_injectable": ["morphine_injectable", 1.0, 1 / 1.0],
    "tylenol #1_injectable": ["morphine_injectable", 0.08, 1 / 0.08],
    "kadian_injectable": ["morphine_injectable", 0.83, 1 / 0.83],
    "codeine contin_injectable": ["morphine_injectable", 0.08, 1 / 0.08],
    "tylenol #2_injectable": ["morphine_injectable", 0.1, 1 / 0.1],
    "tylenol #3_injectable": ["morphine_injectable", 0.125, 1 / 0.125],
    "tylenol #4_injectable": ["morphine_injectable", 0.15, 1 / 0.15],
    "percocet_injectable": ["morphine_injectable", 0.83, 1 / 0.83],
    "hydromorphone_injectable": ["morphine_injectable", 5.0, 1 / 5.0],
    "hydromorph contin_injectable": ["morphine_injectable", 1.5, 1 / 1.5],
    "fentanyl_injectable": ["morphine_injectable", 100.0, 1 / 100.0],
    "tramadol_injectable": ["morphine_injectable", 0.25, 1 / 0.25],
    "meperidine_injectable": ["morphine_injectable", 0.25, 1 / 0.25],
    "nalbuphine_injectable": ["morphine_injectable", 0.2, 1 / 0.2],
    "pentazocine_injectable": ["morphine_injectable", 0.67, 1 / 0.67],
    "butorphanol_injectable": ["morphine_injectable", 0.34, 1 / 0.34],
};

export const conversionFactors = [
    { firstValue: 'oxycodone', secondValue: 'morphine', thirdValue: 1.5, fourthValue: 1 / 1.5 },
    { firstValue: 'hydrocodone', secondValue: 'morphine', thirdValue: 0.9, fourthValue: 1 / 0.9 },
    { firstValue: 'codeine', secondValue: 'morphine', thirdValue: 0.15, fourthValue: 1 / 0.15 },
    { firstValue: 'methadone', secondValue: 'morphine', thirdValue: 3.0, fourthValue: 1 / 3.0 },
    { firstValue: 'suboxone', secondValue: 'morphine', thirdValue: 4.0, fourthValue: 1 / 4.0 },
    { firstValue: 'tapentadol', secondValue: 'morphine', thirdValue: 0.4, fourthValue: 1 / 0.4 },
    { firstValue: 'tylenol #1', secondValue: 'morphine', thirdValue: 0.12, fourthValue: 1 / 0.12 },
    { firstValue: 'kadian', secondValue: 'morphine', thirdValue: 1.2, fourthValue: 1 / 1.2 },
    { firstValue: 'codeine contin', secondValue: 'morphine', thirdValue: 0.3, fourthValue: 1 / 0.3 },
    { firstValue: 'tylenol #2', secondValue: 'morphine', thirdValue: 0.15, fourthValue: 1 / 0.15 },
    { firstValue: 'tylenol #3', secondValue: 'morphine', thirdValue: 0.18, fourthValue: 1 / 0.18 },
    { firstValue: 'tylenol #4', secondValue: 'morphine', thirdValue: 0.24, fourthValue: 1 / 0.24 },
    { firstValue: 'percocet', secondValue: 'morphine', thirdValue: 1.5, fourthValue: 1 / 1.5 },
    { firstValue: 'hydromorphone', secondValue: 'morphine', thirdValue: 4.0, fourthValue: 1 / 4.0 },
    { firstValue: 'hydromorph contin', secondValue: 'morphine', thirdValue: 1.0, fourthValue: 1 / 1.0 },
    { firstValue: 'fentanyl', secondValue: 'morphine', thirdValue: 75.0, fourthValue: 1 / 75.0 },
    { firstValue: 'tramadol', secondValue: 'morphine', thirdValue: 0.1, fourthValue: 1 / 0.1 },
    { firstValue: 'meperidine', secondValue: 'morphine', thirdValue: 0.1, fourthValue: 1 / 0.1 },
    { firstValue: 'nalbuphine', secondValue: 'morphine', thirdValue: 0.08, fourthValue: 1 / 0.08 },
    { firstValue: 'pentazocine', secondValue: 'morphine', thirdValue: 0.33, fourthValue: 1 / 0.33 },
    { firstValue: 'butorphanol', secondValue: 'morphine', thirdValue: 0.17, fourthValue: 1 / 0.17 },
    { firstValue: 'oxycodone_injectable', secondValue: 'morphine_injectable', thirdValue: 1.0, fourthValue: 1 / 1.0 },
    { firstValue: 'hydrocodone_injectable', secondValue: 'morphine_injectable', thirdValue: 0.6, fourthValue: 1 / 0.6 },
    { firstValue: 'codeine_injectable', secondValue: 'morphine_injectable', thirdValue: 0.1, fourthValue: 1 / 0.1 },
    { firstValue: 'methadone_injectable', secondValue: 'morphine_injectable', thirdValue: 2.5, fourthValue: 1 / 2.5 },
    { firstValue: 'suboxone_injectable', secondValue: 'morphine_injectable', thirdValue: 3.5, fourthValue: 1 / 3.5 },
    { firstValue: 'tapentadol_injectable', secondValue: 'morphine_injectable', thirdValue: 1.0, fourthValue: 1 / 1.0 },
    { firstValue: 'tylenol #1_injectable', secondValue: 'morphine_injectable', thirdValue: 0.08, fourthValue: 1 / 0.08 },
    { firstValue: 'kadian_injectable', secondValue: 'morphine_injectable', thirdValue: 0.83, fourthValue: 1 / 0.83 },
    { firstValue: 'codeine contin_injectable', secondValue: 'morphine_injectable', thirdValue: 0.08, fourthValue: 1 / 0.08 },
    { firstValue: 'tylenol #2_injectable', secondValue: 'morphine_injectable', thirdValue: 0.1, fourthValue: 1 / 0.1 },
    { firstValue: 'tylenol #3_injectable', secondValue: 'morphine_injectable', thirdValue: 0.125, fourthValue: 1 / 0.125 },
    { firstValue: 'tylenol #4_injectable', secondValue: 'morphine_injectable', thirdValue: 0.15, fourthValue: 1 / 0.15 },
    { firstValue: 'percocet_injectable', secondValue: 'morphine_injectable', thirdValue: 0.83, fourthValue: 1 / 0.83 },
    { firstValue: 'hydromorphone_injectable', secondValue: 'morphine_injectable', thirdValue: 5.0, fourthValue: 1 / 5.0 },
    { firstValue: 'hydromorph contin_injectable', secondValue: 'morphine_injectable', thirdValue: 1.5, fourthValue: 1 / 1.5 },
    { firstValue: 'fentanyl_injectable', secondValue: 'morphine_injectable', thirdValue: 100.0, fourthValue: 1 / 100.0 },
    { firstValue: 'tramadol_injectable', secondValue: 'morphine_injectable', thirdValue: 0.25, fourthValue: 1 / 0.25 },
    { firstValue: 'meperidine_injectable', secondValue: 'morphine_injectable', thirdValue: 0.25, fourthValue: 1 / 0.25 },
    { firstValue: 'nalbuphine_injectable', secondValue: 'morphine_injectable', thirdValue: 0.2, fourthValue: 1 / 0.2 },
    { firstValue: 'pentazocine_injectable', secondValue: 'morphine_injectable', thirdValue: 0.67, fourthValue: 1 / 0.67 },
    { firstValue: 'butorphanol_injectable', secondValue: 'morphine_injectable', thirdValue: 0.34, fourthValue: 1 / 0.34 },
]
