import React, { useEffect, useState } from "react";
import axios from "axios";

import "./Home.css";
import ApexChart from "../../../components/ApexChart";
import { useStateContext } from "../../../context/AppContext";

export default function Home() {
  const { userToken } = useStateContext();
  const [totalUsers, settotalUsers] = useState([]);
  const [totalPatients, settotalPatients] = useState([]);
  const [totalDocuments, settotalDocuments] = useState([]);
  const [totalMonoDocs, settotalMonoDocs] = useState([]);

  const [pageLoading, setpageLoading] = useState(true);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/auth/user-info`, {
        headers: { token: userToken },
      })
      .then((response) => {
        const filter = response?.data.filter((t) => t.isAdmin !== true);
        settotalUsers(filter);
        setpageLoading(false);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${process.env.REACT_APP_API_URL}/documents/get-list`, {
        headers: { token: userToken },
      })
      .then((res) => {
        settotalDocuments(res?.data);
        setpageLoading(false);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${process.env.REACT_APP_API_URL}/monographs/get-list`, {
        headers: { token: userToken },
      })
      .then((res) => {
        settotalMonoDocs(res?.data);
        setpageLoading(false);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${process.env.REACT_APP_API_URL}/patient/get-date`, {
        headers: { token: userToken },
      })
      .then((res) => {
        settotalPatients(res?.data);
        setpageLoading(false);
      })
      .catch((error) => console.log(error));
  }, [userToken]);

  return (
    <div className="admin-home-main">
      {pageLoading ? (
        <div
          className="addPatient-pageLoader"
          style={{ backgroundColor: "#F5F6F8" }}
        >
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <h3 className="admin-home-title">Welcome To Admin Panel</h3>
          <div className="admin-home-box">
            <div className="admin-home-count shadow">
              TOTAL USERS
              <p>{totalUsers?.length}</p>
            </div>

            <div className="admin-home-count shadow">
              TOTAL PATIENTS
              <p>{totalPatients?.length}</p>
            </div>
            <div className="admin-home-count shadow">
              GUIDELINE DOCUMENTS
              <p>{totalDocuments?.length}</p>
            </div>

            <div className="admin-home-count shadow text-center">
              MONOGRAPH DOCUMENTS
              <p>{totalMonoDocs?.length}</p>
            </div>
          </div>

          <ApexChart Users={totalUsers} />
        </>
      )}
    </div>
  );
}
