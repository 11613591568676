import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { Tooltip } from 'react-tooltip';

import Navbar from '../../components/Navbar'
import Footer from '../../components/Footer'

export default function ForgotPassword() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false)
    const [showPass2, setshowPass2] = useState(false)

    const [email, setemail] = useState('')
    const [newPassword, setnewPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')

    const [emailError, setemailError] = useState('')
    const [newPassError, setnewPassError] = useState('')
    const [confirmPassError, setconfirmPassError] = useState('')
    const [answerError, setanswerError] = useState('')

    const [data, setdata] = useState('')
    const [answer, setanswer] = useState('')
    const [ansMatch, setansMatch] = useState(false)
    const [isLoading, setisLoading] = useState(false)

    const handleNext = async () => {
        if (!email) { return setemailError("enter the email") }

        if (emailError)
            return

        const data = { email }

        try {
            setisLoading(true)
            let user = await axios.post(`${process.env.REACT_APP_API_URL}/auth/forgotPassword`, data)
            setdata(user?.data)
        } catch (error) {
            console.log(error)
            if (error?.response?.status == 500) {
                toast.error("This email is not registered!")
            } else if (error?.response?.status == 401) {
                toast.error("Invalid credentials")
            } else {
                toast.error('Something Went Wrong')
            }
        }
        finally {
            setisLoading(false)
        }
    }

    const handleNext2 = () => {
        if (!answer) { return setanswerError("Please enter the answer") }

        if (answerError)
            return

        if (answer !== data?.answer) { return showError("Incorrect answer") }

        function showError(fieldName) {
            return toast.error(`${fieldName}`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

        setansMatch(true)
    }

    const handleSave = () => {
        if (!newPassword) { return setnewPassError("Please enter the answer") }
        if (!confirmPassword) { return setconfirmPassError("Please enter the answer") }

        if (newPassError || confirmPassError)
            return

        setisLoading(true)
        const formData = { password: newPassword }

        axios.put(`${process.env.REACT_APP_API_URL}/auth/setpassword/${data?.userId}`, formData)
            .then((res) => {
                toast.success(`Password Updated Successfully!`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setisLoading(false)
                setemail('')
                setnewPassword('')
                setconfirmPassword('')
                setdata('')
                setansMatch(false)
                setanswer('')
                navigate('/login')
            }).catch((err) => {
                console.log(err)
                setisLoading(false)
            })
    }

    const handleEmailChange = (e) => {
        setemail(e.target.value)
        const emailRegex = /^[a-zA-Z0-9_.]+@[a-zA-Z]+\.[a-zA-Z.]+$/;
        if (!emailRegex.test(e.target.value)) {
            return setemailError('Please enter a valid email address.')
        }
        setemailError('')
    }

    const handleAnswerChange = (e) => {
        setanswer(e.target.value)
        if (!e.target.value) {
            return setanswerError('Please enter the answer')
        }
        setanswerError('')
    }
    const handleNewPassChange = (e) => {
        setnewPassword(e.target.value)
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!strongPasswordRegex.test(e.target.value)) {
            return setnewPassError(`Password must meet the following requirements: (1) Be at least 8 characters long. (2) Contain at least one lowercase letter. (3) Contain at least one uppercase letter. (4) Contain at least one digit. (5) Contain at least one special character (e.g., @$!%*?&). (6) Avoid using spaces`)
        }
        setnewPassError('')
    }

    const handleConfirmPassChange = (e) => {
        setconfirmPassword(e.target.value)
        if (newPassword !== e.target.value) {
            return setconfirmPassError('Password and confirm password must be identical.')
        }
        setconfirmPassError('')
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            <div className='signup-main'>
                <h1 className='signup-title'>Login / SignUp</h1>
                <div className="signup-breadcrumb">Home <i className="fa-solid fa-chevron-right signup-icon"></i> <span style={{ color: "#b2b2b2" }}>Login / Signup</span></div>
            </div>
            <div className='signup-body'>
                <div className='container'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-7 col-md-11 col-sm-10'>
                            <div className='signup-card shadow-lg'>
                                <h1 className='signup-header'>Forgot Password?</h1>
                                {
                                    !data ?
                                        <>
                                            <div className='signup-inp-box'>
                                                <div className='signup-input-div'>
                                                    <input className='signup-input' style={{ border: emailError && '2px solid #e4002b' }} type='email' value={email} onChange={handleEmailChange} placeholder='Enter your email' />
                                                    {emailError && (
                                                        <i
                                                            className="fa-solid fa-circle-info error-icon"
                                                            data-tooltip-id="my-tooltip1"
                                                            data-tooltip-content={emailError}
                                                            data-tooltip-place="top"
                                                        ></i>
                                                    )}
                                                    <Tooltip id="my-tooltip1" />
                                                </div>
                                            </div>
                                            <button className='signup-btn' disabled={isLoading} onClick={handleNext}>{isLoading ? 'Loading...' : 'Next'}</button>
                                        </>
                                        :
                                        !ansMatch ?
                                            <>
                                                <div className='signup-inp-box mt-5 mb-1'>
                                                    <h5 className='text-white'>{data?.question}</h5>
                                                    <div className='signup-input-div'>
                                                        <input className='signup-input' type='text' style={{ border: answerError && '2px solid #e4002b' }} value={answer} onChange={handleAnswerChange} placeholder='Enter your answer' />
                                                        {answerError && (
                                                            <i
                                                                className="fa-solid fa-circle-info error-icon"
                                                                data-tooltip-id="my-tooltip2"
                                                                data-tooltip-content={answerError}
                                                                data-tooltip-place="top"
                                                            ></i>
                                                        )}
                                                        <Tooltip id="my-tooltip2" />
                                                    </div>
                                                </div>
                                                <button className='signup-btn' onClick={handleNext2}>Next</button>
                                            </>
                                            :
                                            <>
                                                <div className='signup-inp-box mt-5 mb-1'>
                                                    <div className='signup-input-div'>
                                                        <div className='signup-input2' style={{ border: newPassError && '2px solid #e4002b' }}>
                                                            <input type={showPassword ? 'text' : 'password'} value={newPassword} onChange={handleNewPassChange} placeholder='Enter new password' />
                                                            <i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPassword(!showPassword)}></i>
                                                        </div>
                                                        {newPassError && (
                                                            <i
                                                                className="fa-solid fa-circle-info error-icon"
                                                                data-tooltip-id="my-tooltip3"
                                                                data-tooltip-content={newPassError}
                                                                data-tooltip-place="top"
                                                            ></i>
                                                        )}
                                                        <Tooltip id="my-tooltip3" style={{ width: newPassword ? '360px' : '200px' }} />
                                                    </div>
                                                    <div className='signup-input-div'>
                                                        <div className='signup-input2' style={{ border: confirmPassError && '2px solid #e4002b' }}>
                                                            <input type={showPass2 ? 'text' : 'password'} value={confirmPassword} onChange={handleConfirmPassChange} placeholder='Confirm password' />
                                                            <i className={`fa-solid ${showPass2 ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setshowPass2(!showPass2)}></i>
                                                        </div>
                                                        {confirmPassError && (
                                                            <i
                                                                className="fa-solid fa-circle-info error-icon"
                                                                data-tooltip-id="my-tooltip4"
                                                                data-tooltip-content={confirmPassError}
                                                                data-tooltip-place="top"
                                                            ></i>
                                                        )}
                                                        <Tooltip id="my-tooltip4" style={{ width: confirmPassword ? '360px' : '200px' }} />
                                                    </div>
                                                </div>
                                                <button className='signup-btn' disabled={isLoading} onClick={handleSave}>{isLoading ? 'Loading...' : 'Save'}</button>
                                            </>
                                }
                                <p className='signup-botm-text'>Back to? <Link to='/login' style={{ color: "#3195D8", textDecoration: "none" }}>Signin</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
