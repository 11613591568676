import React, { useState, useEffect } from 'react'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

import Home from './Home'
import Signup from './Signup'
import Signin from './Signin'
import Dashboard from './Dashboard'
import { useStateContext } from '../context/AppContext'
import ForgotPassword from './ForgotPassword'
import AdminPanel from './AdminPanel'
import Loading from '../components/Loading'

const DelayedRender = ({ children }) => {
  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShouldRender(true);
    }, 300); // Adjust the delay as needed

    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return shouldRender ? children : <Loading />;
};

export default function Index() {
  const { isAuthenticated, isAdmin, isLoading } = useStateContext();
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'
          element={isLoading ? <Loading /> : (isAuthenticated ? (isAdmin ? <Navigate to="/admin/" element={<AdminPanel />} /> : <Navigate to="/dashboard/" element={<Dashboard />} />) : <Home />)}
        />
        <Route path='/signup'
          element={isLoading ? <Loading /> : (isAuthenticated ? (isAdmin ? <Navigate to="/admin/" element={<AdminPanel />} /> : <Navigate to="/dashboard/" element={<Dashboard />} />) : <Signup />)}
        />
        <Route path='/login'
          element={isLoading ? <Loading /> : (isAuthenticated ? (isAdmin ? <Navigate to="/admin/" element={<AdminPanel />} /> : <Navigate to="/dashboard/" element={<Dashboard />} />) : <Signin />)}
        />
        <Route path='/forgot-password'
          element={isLoading ? <Loading /> : (isAuthenticated ? (isAdmin ? <Navigate to="/admin/" element={<AdminPanel />} /> : <Navigate to="/dashboard/" element={<Dashboard />} />) : <ForgotPassword />)}
        />
        <Route path='/dashboard/*'
          element={
            isLoading ? <Loading />
              : (
                isAuthenticated ? (
                  <DelayedRender>
                    {isAdmin ? <Navigate to="/admin/" element={<AdminPanel />} /> : <Dashboard />}
                  </DelayedRender>
                )
                  : <Navigate to="/login" element={<Signin />} />
              )
          }
        />
        <Route
          path='/admin/*'
          element={
            isLoading ? (
              <Loading />
            ) : (
              isAuthenticated ? (
                <DelayedRender>
                  {isAdmin ? <AdminPanel /> : <Navigate to="/dashboard/" element={<Dashboard />} />}
                </DelayedRender>
              ) : (
                <Navigate to="/login" element={<Signin />} />
              )
            )
          }
        />
      </Routes>
    </BrowserRouter>
  )
}


