import React, { useState } from 'react'
import './Calculator.css'
import DisclaimerModal from '../../../components/DisclaimerModal/DisclaimerModal'
import { child_pugh_scores, opioids, reduce_dose_values, conversionFactors } from '../../../utils/CalculatorInfo'
import { Element, scroller } from 'react-scroll'

export default function Calculator() {
    const [isModal, setisModal] = useState(false)
    const [answer, setanswer] = useState('')

    const [data, setdata] = useState({
        sourceOpioid: 'Morphine',
        sourceAdministrationRoute: 'Oral',
        targetOpioid: 'Morphine',
        targetAdministrationRoute: 'Oral',
        dosage: '',
        crcl: '',
        childPughScore: '',
        reducedose: '25',
    })

    const [errorLabels, setErrorLabels] = useState({
        dosage: false,
        crcl: false,
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setdata((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        if ((name === 'dosage' || name === 'crcl') && isNaN(parseFloat(value))) {
            setErrorLabels((prevData) => ({
                ...prevData,
                [name]: true,
            }));
        } else {
            setErrorLabels((prevData) => ({
                ...prevData,
                [name]: false,
            }));
        }
    };

    const handleReset = () => {
        setdata({
            sourceOpioid: 'Morphine',
            sourceAdministrationRoute: 'Oral',
            targetOpioid: 'Morphine',
            targetAdministrationRoute: 'Oral',
            dosage: '',
            crcl: '',
            childPughScore: '',
            reducedose: '25',
        });

        setErrorLabels({
            dosage: false,
            crcl: false,
        })

        setanswer('')
    };

    const handleConvert = () => {
        if (data.dosage.trim() === '') {
            setErrorLabels((prevLabels) => ({
                ...prevLabels,
                dosage: true,
            }));
            return;
        }

        if (data.crcl.trim() === '') {
            setErrorLabels((prevLabels) => ({
                ...prevLabels,
                crcl: false,
            }));
        }


        if (Object.values(errorLabels).some((label) => label)) {
            return;
        }


        const sourceOpioid = data.sourceOpioid.toLowerCase();
        const targetOpioid = data.targetOpioid.toLowerCase();
        const sourceAdministrationRoute = data.sourceAdministrationRoute;
        const targetAdministrationRoute = data.targetAdministrationRoute;
        const dosage = parseFloat(data.dosage);
        const doseReduction = parseInt(data.reducedose, 10);
        const childPugh = data.childPughScore.trim() === '' ? 'A' : data.childPughScore;
        const crclValue = data.crcl.trim() === '' ? 1 : parseFloat(data.crcl);

        const equivalentDose = calculateEquivalentDose(
            dosage,
            sourceOpioid,
            targetOpioid,
            sourceAdministrationRoute,
            targetAdministrationRoute,
            crclValue,
            childPugh,
            doseReduction
        );

        let Answer = `${dosage} mg of ${sourceOpioid} (${sourceAdministrationRoute}) is equivalent to ${equivalentDose} mg of ${targetOpioid} (${targetAdministrationRoute}).`
        setanswer(Answer)

        scroller.scrollTo(`/answer`, {
            duration: 200,
            smooth: true,
            offset: -100,
        });
    }

    const calculateEquivalentDose = (
        dosage,
        sourceOpioid,
        targetOpioid,
        sourceAdministrationRoute,
        targetAdministrationRoute,
        crclValue,
        childPugh,
        doseReduction
    ) => {
        try {
            let equivalent_dose;
            if (sourceOpioid === targetOpioid && sourceAdministrationRoute === targetAdministrationRoute) {
                return dosage;
            }

            if (sourceAdministrationRoute == 'Oral' && targetAdministrationRoute == 'Oral') {
                equivalent_dose = convert_oral_to_morphine(dosage, sourceOpioid, targetOpioid)
            }

            else if (sourceAdministrationRoute == 'Injectable' && targetAdministrationRoute == 'Injectable') {
                equivalent_dose = convert_injectable_to_morphine(dosage, sourceOpioid, targetOpioid)
            }
            else {
                let oral_to_morphine = convert_oral_to_morphine(dosage, sourceOpioid, "morphine")
                equivalent_dose = convert_injectable_to_morphine(oral_to_morphine, "morphine", targetOpioid)
            }

            const hepaticClearanceMultiplier = { "A": 1.0, "B": 0.8, "C": 0.6 }[childPugh] || 1.0;
            equivalent_dose *= hepaticClearanceMultiplier;
            equivalent_dose *= crclValue / 100;

            // Apply dose reduction
            const doseReductionMultiplier = 1 - (doseReduction / 100);
            equivalent_dose *= doseReductionMultiplier;

            // Round the result to 2 decimal places
            equivalent_dose = Math.round(equivalent_dose * 100) / 100;

            return (equivalent_dose);

        } catch (error) {
            console.log(error)
            console.log('Invalid input. Please check your input values.')
        }
    }

    const convert_oral_to_morphine = (dosage, source_opioid, target_opioid) => {
        const sourceKey = source_opioid.toLowerCase();
        const targetKey = target_opioid.toLowerCase();
        let conversionFactor = 1.0;

        const sourceMatch = conversionFactors.find(factor => factor.firstValue === sourceKey && factor.secondValue === 'morphine');
        if (sourceMatch) {
            conversionFactor *= sourceMatch.thirdValue;
        } else {
            const sourceInjectableMatch = conversionFactors.find(factor => factor.firstValue === 'morphine' && factor.secondValue === sourceKey);
            if (sourceInjectableMatch) {
                conversionFactor /= sourceInjectableMatch.fourthValue;
            }
        }

        const targetMatch = conversionFactors.find(factor => factor.firstValue === "morphine" && factor.secondValue === targetKey);
        if (targetMatch) {
            conversionFactor *= targetMatch.fourthValue;
        } else {
            const targetInjectableMatch = conversionFactors.find(factor => factor.firstValue === targetKey && factor.secondValue === 'morphine');
            if (targetInjectableMatch) {
                conversionFactor /= targetInjectableMatch.thirdValue;
            }
        }

        return dosage * conversionFactor;
    }

    const convert_injectable_to_morphine = (dosage, source_opioid, target_opioid) => {
        const sourceKey = `${source_opioid.toLowerCase()}_injectable`;
        const targetKey = `${target_opioid.toLowerCase()}_injectable`;
        let conversionFactor = 1.0;

        const sourceMatch = conversionFactors.find(factor => factor.firstValue === sourceKey && factor.secondValue === 'morphine_injectable');
        if (sourceMatch) {
            conversionFactor *= sourceMatch.thirdValue;
        } else {
            const sourceInjectableMatch = conversionFactors.find(factor => factor.firstValue === 'morphine_injectable' && factor.secondValue === sourceKey);
            if (sourceInjectableMatch) {
                conversionFactor /= sourceInjectableMatch.fourthValue;
            }
        }

        const targetMatch = conversionFactors.find(factor => factor.firstValue === "morphine_injectable" && factor.secondValue === targetKey);
        if (targetMatch) {
            conversionFactor *= targetMatch.fourthValue;
        } else {
            const targetInjectableMatch = conversionFactors.find(factor => factor.firstValue === targetKey && factor.secondValue === 'morphine_injectable');
            if (targetInjectableMatch) {
                conversionFactor /= targetInjectableMatch.thirdValue;
            }
        }

        return dosage * conversionFactor;
    }

    return (
        <div className='calculator-main'>
            <div className='container'>
                <div className='row d-flex justify-content-center align-items-center'>
                    <div className='col-lg-10 col-md-12'>
                        <div className='calculator-body shadow'>
                            <h2>Opioid Dose Converter</h2>

                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form'>
                                        <p>Source Opioid *</p>
                                        <select name="sourceOpioid" value={data.sourceOpioid} onChange={handleChange}>
                                            {
                                                opioids?.map((t,i) => (
                                                    <option value={t} key={i}>{t}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form'>
                                        <p>Source Administration Route *</p>
                                        <div className='cal-radio-btns'>
                                            <div><input type='radio' name="sourceAdministrationRoute" value="Oral" checked={data.sourceAdministrationRoute === 'Oral'} onChange={handleChange} /> Oral</div>
                                            <div><input type='radio' name="sourceAdministrationRoute" value="Injectable" checked={data.sourceAdministrationRoute === 'Injectable'} onChange={handleChange} /> Injectable</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form'>
                                        <p>Target Opioid *</p>
                                        <select name="targetOpioid" value={data.targetOpioid} onChange={handleChange}>
                                            {
                                                opioids?.map((t, i) => (
                                                    <option value={t} key={i}>{t}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form'>
                                        <p>Target Administration Route *</p>
                                        <div className='cal-radio-btns'>
                                            <div><input type='radio' name="targetAdministrationRoute" value="Oral" checked={data.targetAdministrationRoute === 'Oral'} onChange={handleChange} /> Oral</div>
                                            <div><input type='radio' name="targetAdministrationRoute" value="Injectable" checked={data.targetAdministrationRoute === 'Injectable'} onChange={handleChange} /> Injectable</div>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form mb-0'>
                                        <p>Dosage (mg) *</p>
                                        <input type='text' name="dosage" value={data.dosage} onChange={handleChange} />
                                        <label className='error-label' style={{ color: errorLabels.dosage ? 'red' : 'transparent' }}>Invalid dosage. Please enter a numeric value.</label>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form mb-0'>
                                        <p>Creatinine Clearance (CrCl)</p>
                                        <input type='text' name="crcl" value={data.crcl} onChange={handleChange} />
                                        <label className='error-label' style={{ color: errorLabels.crcl ? 'red' : 'transparent' }}>Invalid CrCl. Please enter a numeric value.</label>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form'>
                                        <p>Child-Pugh Score</p>
                                        <select name="childPughScore" value={data.childPughScore} onChange={handleChange}>
                                            {
                                                child_pugh_scores?.map((t,i) => (
                                                    <option value={t} key={i}>{t}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form'>
                                        <p>Reduce dose by (%) *</p>
                                        <select name="reducedose" value={data.reducedose} onChange={handleChange}>
                                            {
                                                reduce_dose_values?.map((t,i) => (
                                                    <option value={t} key={i}>{t}</option>
                                                ))
                                            }
                                        </select>
                                    </div>
                                </div>

                                <div className='col-12'>
                                    <div className='cal-form'>
                                        <span onClick={() => setisModal(!isModal)}>Disclaimer: Click here to read the disclaimer</span>
                                    </div>
                                </div>

                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form'>
                                        <button onClick={handleReset}>RESET</button>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <div className='cal-form'>
                                        <button onClick={handleConvert}>CONVERT</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Element name='/answer'>
                <div className='cal-answer'>
                    <h3>Opioid Dose: </h3>
                    <div className='shadow'>{answer}</div>
                </div>
            </Element>

            {isModal && <DisclaimerModal setisModal={setisModal} />}
        </div>
    )
}
