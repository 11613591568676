import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import Select from "react-select";

import { useStateContext } from '../../../context/AppContext';
import './AddMedCon.css'

export default function AddMedCon() {
    const navigate = useNavigate()
    const location = useLocation()
    const { userToken } = useStateContext()
    const [medCondition, setmedCondition] = useState('')
    const [medConErr, setmedConErr] = useState('')
    const [medConVal, setmedConVal] = useState([])
    const [medConValErr, setmedConValErr] = useState('')

    const [isLoading, setisLoading] = useState(false)
    const [pageLoading, setpageLoading] = useState(false)

    const onSubmit = async () => {
        if (!medCondition) { return setmedConErr("Please enter the medical condition") }
        if (medConVal.length == 0) { return setmedConValErr("Please select the medical condition values") };
        if (medConErr || medConErr)
            return

        setisLoading(true)

        const formData = { medCondition, medConVal }

        try {
            let user = await axios.post(`${process.env.REACT_APP_API_URL}/medCondition/save`, formData, { headers: { token: userToken } })
            toast.success('Medical Condition Saved Successfully!');
            setmedCondition('')
            navigate('/admin/medconditions')
        } catch (error) {
            console.log(error)
            if (error?.response?.status == 500) {
                toast.error("Something went wrong")
            } else if (error?.response?.data?.error) {
                toast.error(error.response.data.error);
            } else {
                toast.error('Something Went Wrong')
            }
        }
        finally {
            setisLoading(false)
        }
    };

    const handleUpdate = async () => {
        if (!medCondition) { return setmedConErr("Please enter the medical condition") }
        if (medConVal.length == 0) { return setmedConValErr("Please select the medical condition values") };
        if (medConErr || medConErr)
            return

        setisLoading(true)

        const formData = { medCondition, medConVal }

        try {
            let user = await axios.put(`${process.env.REACT_APP_API_URL}/medCondition/update/${location?.state?.elem?._id}`, formData, { headers: { token: userToken } })
            toast.success('Medical Condition Updated Successfully!');
            setmedCondition('')
            navigate('/admin/medconditions')
        } catch (error) {
            console.log(error)
            if (error?.response?.status == 500) {
                toast.error("Something went wrong")
            } else if (error?.response?.data?.error) {
                toast.error(error.response.data.error);
            } else {
                toast.error('Something Went Wrong')
            }
        }
        finally {
            setisLoading(false)
        }
    };

    const handleMedConChange = (e) => {
        setmedCondition(e.target.value)
        const nameRegex = /^[A-Za-z ]{2,}$/;
        if (!nameRegex.test(e.target.value)) {
            return setmedConErr('Please enter only letters')
        }
        setmedConErr('')
    }

    const handleMedConValChange = (event) => {
        if (event.length !== 0) {
            setmedConValErr('')
            setmedConVal(event);
        } else {
            setmedConVal([])
        }
    };

    const option = [
        { label: "HBA1c", value: "hba1c" },
        { label: "Fasting blood sugar", value: "fastingBlood" },
        { label: "LDL", value: "ldl" },
        { label: "HDL", value: "hdl" },
        { label: "Triglycerides", value: "triglycerides" },
        { label: "HDL ratio", value: "hdlRatio" },
        { label: "Height", value: "height" },
        { label: "Weight", value: "weight" },
        { label: "BMI", value: "bmi" },
        { label: "Sodium", value: "sodium" },
        { label: "Potassium", value: "potassium" },
        { label: "Blood pressure", value: "bloodPressure" },
        { label: "Creatine clearance", value: "creatineClearance" },
        { label: "Child pugh score", value: "childPughScore" },
        { label: "Ferritin", value: "ferritin" },
        { label: "TSH", value: "tsh" },
        { label: "T4", value: "t4" },
        { label: "T3", value: "t3" },
        { label: "Vitamin D", value: "vitaminD" },
        { label: "Vitamin B12", value: "vitaminB12" },
    ]

    useEffect(() => {
        if (location?.state?.elem) {
            setpageLoading(true)
            setmedCondition(location?.state?.elem?.MedicalCondition)
            setmedConVal(JSON.parse(location?.state?.elem?.MedicalConditionValue))
            setpageLoading(false)
        }
    }, [location?.state?.elem])

    return (
        <div className='medcon-main' style={{position:'relative'}}>
            {
                pageLoading ? <div className='addPatient-pageLoader' style={{ backgroundColor: "#F5F6F8" }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                    :
                    <>
                        <h3 className='medcon-title'>{location?.state?.elem ? 'Update' : 'Add'} Medical Condition</h3>
                        <div className='admin-card-div'>
                            <input className='form-control medCon-input' style={{ border: medConErr && '1px solid #e4002b' }} type='text' value={medCondition} onChange={handleMedConChange} placeholder='Name' />
                            {medConErr && (
                                <i
                                    className="fa-solid fa-circle-info med-error-icon"
                                    data-tooltip-id="my-tooltip1"
                                    data-tooltip-content={medConErr}
                                    data-tooltip-place="top"
                                ></i>
                            )}
                            <Tooltip id="my-tooltip1" />
                        </div>
                        <div className='admin-card-div'>
                            <Select
                                onChange={handleMedConValChange}
                                options={option}
                                value={medConVal}
                                placeholder="Select Medical Condition Values"
                                className="addMedCon-select"
                                isMulti
                            />
                            {medConValErr && (
                                <i
                                    className="fa-solid fa-circle-info med-error-icon"
                                    data-tooltip-id="my-tooltip2"
                                    data-tooltip-content={medConValErr}
                                    data-tooltip-place="top"
                                ></i>
                            )}
                            <Tooltip id="my-tooltip2" />
                        </div>
                        {
                            location?.state?.elem ?
                                <button className='medcon-btn' disabled={isLoading} onClick={handleUpdate}>{isLoading ? 'Loading...' : 'Update'}</button>
                                :
                                <button className='medcon-btn' disabled={isLoading} onClick={onSubmit}>{isLoading ? 'Loading...' : 'Add +'}</button>
                        }
                    </>
            }
        </div>
    )
}
