import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function SelectReco() {
    
    const navigate = useNavigate();

    return (
        <div className='reco-main'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='reco-card reco-card-2 shadow' style={{ backgroundColor: "#FFA07A", color: "black",overflow:'hidden' }} onClick={() => navigate(`/dashboard/patient/${'get-recommendation'}`)}>
                            <h3>Get Recommendations</h3><i className="fa-solid fa-hospital-user fs-3"></i>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='reco-card reco-card-2 shadow' style={{ backgroundColor: "#3A76EF", color: "white",overflow:'hidden' }} onClick={() => navigate(`/dashboard/patient/${'view-recommendation'}`)}>
                            <h3>View Recommendations</h3><i className="fa-solid fa-pen-to-square fs-3"></i>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='reco-card reco-card-2 shadow' style={{ backgroundColor: "#3498db", color: "white",overflow:'hidden' }} onClick={() => navigate(`/dashboard/converter`)}>
                            <h3>Opioid dose converter</h3><i className="fa-solid fa-calculator fs-3"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
