import React from 'react'
import './Applications.css'

export default function Applications() {
    return (
        <div className='app-main'>
            <div className='container'>
                <div className='d-flex justify-content-center'><button className='hero-btn'>TESTIMONIALS</button></div>
                <h1 className='app-title'>Discover What Our <span className='hero-span'>Clients Share.</span></h1>
                <div className='app-text-div'><p className='app-text'>Discover the impact of our personalized healthcare through testimonials. Hear stories of healing and satisfaction from our valued clients.</p></div>
                <div className='row d-flex justify-content-center'>
                    <div className='col-lg-11 col-md-12'>
                        <div className='apps-div'>
                            <div className='apps-box shadow-lg'>
                                <div className='app-icon-div'><img src='https://faculty.mdanderson.org/content/dam/mdanderson/images/fis/james_allison.jpg' className='apps-img' /></div>
                                <p className='apps-title'>Dr. James</p>
                                <p>Cardiologist</p>
                                <p className='apps-text'>ECHO AI impresses with precision and personalization, positively impacting patient outcomes and collaboration in cardiology.</p>
                            </div>
                            <div className='apps-box shadow-lg'>
                                <div className='app-icon-div'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR47RVKXdxs7S4uNEJbPUkSG3gGkAxv5DL8Gw&usqp=CAU' className='apps-img' /></div>
                                <p className='apps-title'>Dr. Sarah</p>
                                <p>Pediatrician</p>
                                <p className='apps-text'>Transformative solutions from ECHO AI enhance tailored care for every child in my practice.</p>
                            </div>
                            <div className='apps-box shadow-lg'>
                                <div className='app-icon-div'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSLSQbHq0YQu1Q8t7xfkERolUUPkiFHogMHeA&usqp=CAU' className='apps-img' /></div>
                                <p className='apps-title'>Dr. Michael</p>
                                <p>Oncologist</p>
                                <p className='apps-text'>ECHO AI streamlines oncology practice, ensuring precision and improved patient outcomes.</p>
                            </div>
                            <div className='apps-box shadow-lg'>
                                <div className='app-icon-div'><img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSMWyfc__fo8SDgP9CHStwWRAAtuxg3mbKFlQ&usqp=CAU' className='apps-img' /></div>
                                <p className='apps-title'>Nurse Elena</p>
                                <p>Cardiologist</p>
                                <p className='apps-text'>ECHO AI impresses with precision and personalization, positively impacting patient outcomes and collaboration in cardiology</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
