import './App.css';
import Routes from '../src/pages/Routes'
import { ToastContainer } from 'react-toastify';
import AppContext from './context/AppContext';
function App() {
  return (
    <>
      <AppContext>
        <Routes />
        <ToastContainer />
      </AppContext>
    </>
  );
}

export default App;
