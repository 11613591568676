import React, { useEffect } from 'react'
import AOS from 'aos';
import { useNavigate } from 'react-router-dom';

import './Hero.css'
import 'aos/dist/aos.css';
import { useStateContext } from '../../context/AppContext';

export default function Hero() {
  const navigate = useNavigate();
  const { isAuthenticated } = useStateContext();

  useEffect(() => {
    AOS.init({
      duration: 500,
      offset: 200,
      delay: 200,
    });
  }, []);
  
  return (
    <div className='hero-main'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <div className='hero-btn-div'><button className='hero-btn'>Your AI Health Collaborator</button></div>
            <h1 className='hero-title' data-aos="fade-up">Elevating Healthcare with Smart Dose <span className='hero-span'>Recommendations</span></h1>
            <div className='hero-input-div'><div className='hero-input-box'>
              <input className='hero-input' disabled placeholder='Explore Dose Recommendations' />
              <div className='hero-icon-box' onClick={() => navigate(isAuthenticated ? '/dashboard' : '/login')}>
                <i className="fa-solid fa-arrow-up hero-icon"></i>
              </div></div>
            </div>
            {/* <li className='hero-li'>How exactly it works ?</li> */}
            <div className='hero-portfolio'>
              <div><span className='portfolio-title'>895<span style={{ color: "#3195D8" }}>+</span></span> <br /> <span className='protofolio-text'>TREATED PATIENTS</span></div>
              <div><span className='portfolio-title'>437<span style={{ color: "#3195D8" }}>k</span></span> <br /> <span className='protofolio-text'>HAPPY PATIENTS</span></div>
              <div><span className='portfolio-title'>10<span style={{ color: "#3195D8" }}>+</span></span> <br /> <span className='protofolio-text'>NEW TECHNOLOGIES</span></div>
            </div>
          </div>
          <div className='col-lg-6'>
            <div className='h-100 d-flex justify-content-center align-items-center'>
              <video src='https://aimax.wpengine.com/wp-content/uploads/2023/06/slider-2.webm' autoPlay className='hero-video' />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
