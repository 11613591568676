import React, { useState } from 'react'
import './VideoPlay.css'
import img1 from '../../assets/images/video-bg.png'

export default function VideoPlay() {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [showBall, setShowBall] = useState(false);

  const handleMouseMove = (e) => {
    // setTimeout(() => {
    setMousePosition({ x: e.clientX, y: e.clientY });
    // }, 10);
  };

  const handleMouseEnter = () => {
    setShowBall(true);
  };

  const handleMouseLeave = () => {
    setShowBall(false);
  };


  return (
    <>
      <div
        className='video-main'
      >
        <img src={img1} className='img-fluid vm-img'
          onMouseMove={handleMouseMove}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={() => console.log('workign')}
        />

        {/* <div
          className='ball'
        ><span className='ball-text'>Play</span></div> */}
      </div>
    </>
  )
}
