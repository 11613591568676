// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCGR23clNykGtU-65HdfC9jcKXxEMvBIU8",
  authDomain: "echo-c0da3.firebaseapp.com",
  projectId: "echo-c0da3",
  storageBucket: "echo-c0da3.appspot.com",
  messagingSenderId: "716163063519",
  appId: "1:716163063519:web:f772a5adf398bbd9d5136a",
  measurementId: "G-V0E0Y4HWP8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app)