import React from 'react'
import './Modal.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useStateContext } from '../../context/AppContext';
export default function Modal({ setisModal, patientData, data, setdata }) {
    const { userToken } = useStateContext()
    const handleClick = () => {
        axios.delete(`${process.env.REACT_APP_API_URL}/patient/delete/${patientData?._id}`, { headers: { token: userToken } })
            .then((response) => {
                const filter = data.filter((t) => t._id !== patientData._id)
                setdata(filter)
                axios.delete(`${process.env.REACT_APP_API_URL}/recommendation/delete-all/${patientData?._id}`, { headers: { token: userToken } })
                    .then((response) => {
                        toast.success(`Patient Deleted Successfully`);
                        setisModal(false)
                    }).catch((err) => { console.log(err) })

            }).catch((err) => {
                console.log(err)
            })
    }
    return (
        <div className="stripepayment-overlay">
            <div className="stripepayment-modal">
                <div
                    className={"stripe-close-icon"}
                    onClick={() => setisModal(false)}
                ><i class="fa-solid fa-xmark"></i></div>
                <div className="d-grid align-items-center gap-3">

                    <div className="">
                        <h3 className='modal-title'>Are you sure you want to delete this Patient?</h3>
                        <div className='modal-body'>
                            {/* <div className='modal-body-text'><span>Name:</span> Andewson</div>
                            <div className='modal-body-text'><span>Id:</span> 2</div> */}
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-sm-6 col-6'><span className='modal-body-title'>Patient id:</span></div>
                                <div className='col-lg-8 col-md-6 col-sm-6 col-6'><span className='modal-body-text'>{patientData?.patientNo}</span></div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6 col-sm-6 col-6'><span className='modal-body-title'>Name:</span></div>
                                <div className='col-lg-8 col-md-6 col-sm-6 col-6'><span className='modal-body-text'>{patientData?.name}</span></div>
                            </div>
                            <div className='d-flex gap-1'>
                                <button className='modal-btn' onClick={handleClick}>Yes</button>
                                <button className='modal-btn2' onClick={() => setisModal(false)}>No</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
