import React from 'react'
import { Link } from 'react-router-dom';

import './Faq.css'
import { useStateContext } from '../../context/AppContext'

export default function Faq() {
    const { isAuthenticated } = useStateContext();
    return (
        <div className='faq-main'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-lg-6 col-md-12 p-0'>
                        <div className='faq-left-img'>
                            <div className='faq-discover'>
                                <div className='discover-left'>
                                    <p className='faq-discover-title'>Getting Started</p>
                                    <p className='faq-discover-text'>Kickstart your journey with our user-friendly interface. Explore the basics, from account setup to navigating through our intuitive features.</p>
                                    <Link to={isAuthenticated ? '/dashboard' : '/login'} className='faq-discover-btn'>Begin Your Journey</Link>
                                </div>
                                <div className='discover-right'>
                                    <p className='faq-discover-title'>Privacy and Security</p>
                                    <p className='faq-discover-text'>Your privacy is our priority. Learn about the robust security measures in place to safeguard your health information.</p>
                                    <Link to={isAuthenticated ? '/dashboard' : '/login'} className='faq-discover-btn'>Begin Your Journey</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-12 p-0'>
                        <div className='faq-right'>
                            <button className='hero-btn'>OUR EXPERTS ANSWER</button>
                            <h1 className='faq-title'>Frequently Asked <span className='hero-span'>Questions</span></h1>
                            <p className='faq-text'>Explore common questions and find clarity in our FAQ section. We've compiled comprehensive answers to provide you with the information you need, ensuring a smooth and informed journey through our healthcare solutions.</p>

                            <div className='faq-accordion'>
                                <div className="accordion" id="accordionExample">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                            >
                                                How does your AI-driven dose recommendation work?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseOne"
                                            className="accordion-collapse collapse show"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                Our AI analyzes the medical history, documents, and patient information provided by the doctor. Using advanced algorithms, it then generates personalized dose recommendations based on the latest medical research and best practices.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo"
                                            >
                                                Is my personal health information secure?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseTwo"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                Absolutely. We prioritize the security and confidentiality of your health data. Our platform adheres to stringent privacy protocols and utilizes industry-standard encryption to safeguard your personal health information.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button
                                                className="accordion-button collapsed"
                                                type="button"
                                                data-bs-toggle="collapse"
                                                data-bs-target="#collapseThree"
                                                aria-expanded="false"
                                                aria-controls="collapseThree"
                                            >
                                                How can I integrate your healthcare solutions into my practice?
                                            </button>
                                        </h2>
                                        <div
                                            id="collapseThree"
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample"
                                        >
                                            <div className="accordion-body">
                                                Integrating our solutions is seamless. We provide user-friendly interfaces and comprehensive support to ensure a smooth transition. Our team is readily available to assist you in incorporating our innovative healthcare tools into your daily practice.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
