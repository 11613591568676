import React from 'react'
import 'react-toastify/dist/ReactToastify.css';
import './DisclaimerModal.css'
export default function Modal({ setisModal }) {
    return (
        <div className="disclaimer-overlay">
            <div className="disclaimer-modal">
                <div
                    className={"stripe-close-icon"}
                    onClick={() => setisModal(false)}
                ><i className="fa-solid fa-xmark"></i></div>
                <div className="d-grid align-items-center gap-3">
                    <h3>Disclaimer:</h3>
                    <p>Morph provides the code for opioid dose conversion and equivalent calculations ("the Code") for informational purposes only. The Code is intended to be a general reference tool and should not be considered a substitute for professional medical advice, diagnosis, or treatment.</p>
                    <p>While we strive to provide accurate and up-to-date information, the Code is provided "as is" without any representations or warranties, express or implied. Morph makes no representations or warranties in relation to the accuracy, completeness, or suitability of the Code for any particular purpose. The use of the Code is at your own risk. x </p>
                    <p>The Code should not be used for making clinical decisions or prescribing medications. Opioid conversions and dosing require careful consideration of individual patient factors, medical history, and clinical judgment. Users of the Code should always consult with qualified healthcare professionals for any medical or clinical advice or decisions.</p>
                    <p>Morph shall not be liable for any loss, injury, or damage arising from the use or misuse of the Code. By using the Code, you agree to indemnify and hold Morph harmless from any claims, demands, or actions arising out of or in connection with your use of the Code.</p>
                    <p>This disclaimer is subject to change without notice. By using the Code, you agree to the terms and conditions outlined in this disclaimer.</p>
                    <p>Please consult with legal counsel to ensure this disclaimer meets the specific legal requirements for your jurisdiction and use case.</p>
                </div>
            </div>
        </div>
    )
}
