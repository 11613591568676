import React, { useState, useEffect } from 'react'
import { useParams, useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios';
import { Element, scroller } from 'react-scroll';
import { toast } from 'react-toastify';

import './PatientDetail.css'
import { useStateContext } from '../../../context/AppContext';

export default function PatientDetail() {
    const location = useLocation()
    const navigate = useNavigate()
    const { id } = useParams();
    const purpose = new URLSearchParams(window.location.search).get('purpose');
    const { userToken } = useStateContext()

    const [data, setdata] = useState([])
    const [aiReco, setaiReco] = useState([])
    const [currentData, setcurrentData] = useState([])
    const [medConVal, setmedConVal] = useState([])

    const [selectedDisease, setselectedDisease] = useState('')
    const [message, setmessage] = useState('')

    const [selectDisError, setselectDisError] = useState('')
    const [messError, setmessError] = useState('')

    const [pageLoading, setpageLoading] = useState(false)
    const [isLoading, setisLoading] = useState(false)

    useEffect(() => {
        setpageLoading(true)

        axios
            .get(`${process.env.REACT_APP_API_URL}/recommendation/get`, { headers: { token: userToken } })
            .then((response) => {
                if (purpose === 'get-recommendation') {
                    axios
                        .get(`${process.env.REACT_APP_API_URL}/patient/get`, { headers: { token: userToken } })
                        .then((res) => {
                            const filter = res.data.filter((t, i) => t.patientNo === JSON.parse(id))
                            setdata(filter[0])

                            const filter2 = response.data.filter((t, i) => t.patientId === filter[0]._id)
                            setcurrentData(filter2)
                            setpageLoading(false)

                            if (location?.state?.elem) {
                                const filterReco = response.data.filter((t) => t._id === location?.state?.elem)
                                setaiReco(filterReco)
                                setselectedDisease(filterReco[0].medicalConditions && JSON.parse(filterReco[0].medicalConditions)[0])
                                setmessage(filterReco[0].message)
                            }
                        })
                        .catch((error) => console.log(error));
                }
                if (purpose === 'view-recommendations') {
                    if (location?.state?.elem) {
                        const filterReco = response.data.filter((t) => t._id === location?.state?.elem)
                        setdata(filterReco[0])
                        setselectedDisease(filterReco[0].medicalConditions && JSON.parse(filterReco[0].medicalConditions)[0])
                        setmessage(filterReco[0].message)
                        setaiReco(filterReco)
                        setpageLoading(false)
                    }
                }
            }).catch((error) => console.log(error));

        axios
            .get(`${process.env.REACT_APP_API_URL}/medCondition/get`, { headers: { token: userToken } })
            .then((res) => {
                setmedConVal(res.data)
            })
            .catch((error) => console.log(error));
    }, [id, location?.state?.elem, userToken, purpose])


    const handleGetReco = () => {
        if (!selectedDisease) {
            return setselectDisError("error")
        }

        if (currentData.length !== 0) {
            const filter = currentData.filter((t) => t.medicalConditions === JSON.stringify([selectedDisease]))
            if (filter.length !== 0) {
                toast(
                    <div className="custom-toast">
                        <p className="message">You have already taken recommendation for {selectedDisease}. Are you sure you want to get again?</p>
                        <div className="button-container-toast">
                            <button className="button-toast yes" onClick={() => {
                                toast.dismiss();
                                handleSubmit()
                            }}>Yes</button>
                            <button className="button-toast no" onClick={() => {
                                toast.dismiss();
                            }}>No</button>
                        </div>
                    </div>
                );
            } else { handleSubmit() }
        } else {
            handleSubmit()
        }
    }


    const handleSubmit = () => {
        setisLoading(true)

        const dob = new Date(data.age);
        const currentDate = new Date();
        const ageInMilliseconds = currentDate - dob;
        const ageInYears = Math.floor(ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000));

        let user_data = {
            name: data.name, gender: data.gender, age: ageInYears, medicalCondition: JSON.parse(data.medicalConditions), medicationCrcl: JSON.parse(data.medicationsCrCl), hba1c: data.hba1c,
            fastingBlood: data.fastingBlood, ldl: data.LDL, hdl: data.HDL, triglycerides: data.triglycerides, hdlRatio: data.HDLRatio, height: data.height, weight: data.weight, bmi: data.BMI, sodium: data.sodium, potassium: data.potassium,
            bloodPressure: data.bloodPressure, creatineClearance: data.creatineClearance, childPughScore: data.childPughScore, ferritin: data.ferritin, tsh: data.TSH, t4: data.T4, t3: data.T3, vitaminD: data.vitaminD,
            vitaminB12: data.vitaminb12
        }

        let filter1 = medConVal.filter((t) => t.MedicalCondition.toLowerCase() === selectedDisease.toLowerCase())
        let medicalConVal = filter1.length !== 0 ? JSON.parse(filter1[0]?.MedicalConditionValue) : []
        let medicalConditionValues = medicalConVal.map(item => item.value);

        const formData = {
            medicalCondition: selectedDisease,
            user_data: user_data,
            medicalConditionValues,
            message
        }

        axios.post(`${process.env.REACT_APP_AI_URL}/chat`, formData, { headers: { token: userToken } })
            .then((response) => {
                user_data.patientId = data?._id;
                user_data.patientNo = data?.patientNo;
                user_data.recommendation = response.data;
                user_data.medicalCondition = [selectedDisease];
                user_data.message = message
                let formData2 = user_data;

                axios.post(`${process.env.REACT_APP_API_URL}/recommendation/save`, formData2, { headers: { token: userToken } })
                    .then((response) => {
                        setisLoading(false)
                        toast.success(`Recommendation Fetched Successfully`);
                        aiReco.push(response?.data?.newReco)
                        setTimeout(() => {
                            window.scrollTo({
                                top: document.body.scrollHeight,
                                behavior: 'smooth',
                            });
                        }, 300);
                    }).catch((err) => {
                        console.log(err)
                        setisLoading(false)
                    })

            }).catch((err) => {
                toast.error(err?.response?.data?.error)
                setisLoading(false)
            })
    }
    return (
        <div className='patientdetail-main'>
            {
                pageLoading ? <div className='addPatient-pageLoader'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                    :
                    <>
                        <i className="fa-solid fa-arrow-left patientdetail-icon" onClick={() => navigate(-1)}></i> <br />

                        <div className='container-fluid'>
                            <div className='row'>
                                <div className='col col-lg-3 col-md-12 col-sm-12 col-12'>
                                    <div className='pd-sidebar'>
                                        <div className='pd-sidebar-profile shadow'>
                                            <h4 className='pd-sidebar-head'>{data?.name}</h4>
                                            <div className='row'>
                                                <div className='col-6'><span className='pd-sidebar-title'>Patient Id:</span></div>
                                                <div className='col-6'><span className='pd-sidebar-text'>{data?.patientNo}</span></div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'><span className='pd-sidebar-title'>Gender:</span></div>
                                                <div className='col-6'><span className='pd-sidebar-text'>{data?.gender}</span></div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'><span className='pd-sidebar-title'>Date of birth:</span></div>
                                                <div className='col-6'><span className='pd-sidebar-text'>{data?.age}</span></div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6'><span className='pd-sidebar-title'>Height:</span></div>
                                                <div className='col-6'><span className='pd-sidebar-text'>{data?.height}</span></div>
                                            </div>
                                            {/* <div className='row'>
                                                    <div className='col-6'><span className='pd-sidebar-title'>License number:</span></div>
                                                    <div className='col-6'><span className='pd-sidebar-text'>{data?.licenseNum}</span></div>
                                                </div> */}
                                        </div>

                                        <div className='pd-sidebar-disease shadow'>
                                            <h4 className='pd-sidebar-head'>Disease</h4>
                                            <div className='row'>
                                                <div className='col-12'><span className='pd-sidebar-title'>Medical conditions:</span></div>
                                                <div className='col-12'>
                                                    <span className='pd-sidebar-text'>
                                                        {data?.medicalConditions
                                                            && JSON.parse(data.medicalConditions).map((t, index) => (
                                                                <p className='mb-0' style={{ padding: "0.5rem 0.5rem 0" }} key={index}>
                                                                    {(index + 1 + ") ") + t}
                                                                </p>
                                                            ))}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            purpose === 'get-recommendation' && (
                                                <button className='vpr-btn2 shadow' onClick={() => navigate('/dashboard/add-patient', { state: { id: data?.patientNo } })}>
                                                    Edit Patient <i className="fa-solid fa-pen-to-square fs-5"></i>
                                                </button>
                                            )
                                        }

                                        <button className='vpr-btn2 shadow' onClick={() => navigate(`/dashboard/pre-responses/${purpose}`, { state: { id: purpose === 'get-recommendation' ? data?._id : data?.patientId } })}>
                                            <div className='text-start'>View Previous Recommendations</div> <i className="fa-solid fa-notes-medical fs-5"></i>
                                        </button>
                                    </div>
                                </div>
                                <div className='col col-lg-9 col-md-12 col-sm-12 col-12'>
                                    <div className='pd-body shadow'>
                                        <div className='row'>
                                            <div className='col-12 p-0'>
                                                <div className='pd-body-box'>
                                                    <p className='pd-body-title'>LAB REPORT</p>
                                                    {/* <p className='pd-body-title'>{new Date(data?.createdAt).toLocaleString()}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'>
                                                <div className='pd-sidebar-title'>Medications:</div>
                                                <div className='pd-sidebar-text'>
                                                    {data?.medicationsCrCl && JSON.parse(data.medicationsCrCl).map((t, index, array) => (
                                                        <p className='mb-0' key={index}>
                                                            {t}{index < array.length - 1 && ','}
                                                        </p>
                                                    ))}
                                                </div>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>HBA1c:</div><div className='pd-sidebar-text'>{data?.hba1c}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Fasting blood sugar:</div><div className='pd-sidebar-text'>{data?.fastingBlood}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>LDL:</div><div className='pd-sidebar-text'>{data?.LDL}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>HDL:</div><div className='pd-sidebar-text'>{data?.HDL}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Triglycerides:</div><div className='pd-sidebar-text'>{data?.triglycerides}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>HDL ratio:</div><div className='pd-sidebar-text'>{data?.HDLRatio}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>BMI:</div><div className='pd-sidebar-text'>{data?.BMI}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Sodium:</div><div className='pd-sidebar-text'>{data?.sodium}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Potassium:</div><div className='pd-sidebar-text'>{data?.potassium}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Weight:</div><div className='pd-sidebar-text'>{data?.weight}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Blood pressure:</div><div className='pd-sidebar-text'>{data?.bloodPressure}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Creatine clearance:</div><div className='pd-sidebar-text'>{data?.creatineClearance}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Child pugh score:</div><div className='pd-sidebar-text'>{data?.childPughScore}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Ferritin:</div><div className='pd-sidebar-text'>{data?.ferritin}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Vitamin D:</div><div className='pd-sidebar-text'>{data?.vitaminD}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>Vitamin B12:</div><div className='pd-sidebar-text'>{data?.vitaminb12}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>TSH:</div><div className='pd-sidebar-text'>{data?.TSH}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>T4:</div><div className='pd-sidebar-text'>{data?.T4}</div></div>
                                            <div className='col-lg-4 col-md-6 col-sm-6 col-12 pd-body-content'><div className='pd-sidebar-title'>T3:</div><div className='pd-sidebar-text'>{data?.T3}</div></div>
                                        </div>
                                    </div>
                                    {
                                        purpose === 'get-recommendation' ? (
                                            <div>
                                                <h5>Medication required for:</h5>
                                                <select
                                                    className="form-select pd-select shadow"
                                                    id="exampleFormControlSelect1" value={selectedDisease} onChange={e => { setselectedDisease(e.target.value); setselectDisError('') }} style={{ border: selectDisError && '1px solid #e4002b' }}>
                                                    <option value="">Select Disease</option>
                                                    {data?.medicalConditions
                                                        && JSON.parse(data.medicalConditions).map((t, index) => (
                                                            <option value={t} key={index}>
                                                                {t}
                                                            </option>
                                                        ))}
                                                </select>
                                                <div><textarea className='pd-textArea shadow' value={message} onChange={e => { setmessage(e.target.value); setmessError('') }} style={{ border: messError && '1px solid #e4002b' }} placeholder='Enter Message' /></div>
                                                <div className='d-flex justify-content-end'><button className='pd-getReco-btn shadow' disabled={isLoading} onClick={handleGetReco}>Get Recommendation</button></div>
                                            </div>
                                        )
                                            :
                                            <div>
                                                <h5>Medication required for:</h5>
                                                <input
                                                    className="form-control pd-select bg-white shadow" value={selectedDisease} disabled />
                                                <div><textarea className='pd-textArea bg-white shadow' value={message} disabled /></div>
                                            </div>
                                    }

                                    {
                                        aiReco.length !== 0 && (
                                            aiReco?.map((item, index) => (
                                                <div>
                                                    <div className='pd-table-div'>
                                                        <table className='pd-responseTable mb-0 mt-3' key={index}>
                                                            <thead>
                                                                <tr>
                                                                    <th>No</th>
                                                                    <th>Drug Name</th>
                                                                    <th>Dosage Plan</th>
                                                                    <th>Creation Time</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className='pd-col1'>{index + 1}</td>
                                                                    <td className='pd-col2'>{item.recommendation["Drug Name"]}</td>
                                                                    <td className='pd-col3'>{item.recommendation["Dosage Plan"]}</td>
                                                                    <td className='pd-col4'>{new Date(item?.createdAt).toLocaleString()}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <p className='pd-disclaimer'><p>Disclaimer:</p> <div>This is an AI generated recommendation and is for the help of doctors only. All medications should be only prescribed by a registered Doctor.</div></p>
                                                </div>
                                            ))
                                        )
                                    }

                                </div>
                            </div>
                        </div>

                        {
                            isLoading &&
                            <div className='getReco-Loader'>
                                <p className='getReco-title'>We are preparing your recommendation</p>
                                <div className="spinner-border getReco-Load mb-2" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        }
                    </>
            }
        </div>
    )
}
