import React from 'react'
import styled, { keyframes } from 'styled-components';

import './OurStory.css'
import v1 from '../../assets/videos/brain.webm'
const jellyAnimation = keyframes`
  0%, 100% {
    border-radius: 30%;
    // transform: scale(1);
  }
  25% {
    border-radius: 60% 40%;
    // transform: scale(1.2);
  }
  50% {
    border-radius: 30% 30%;
    // transform: scale(1);
  }
  75% {
    border-radius: 40% 60%;
    // transform: scale(1.2);
  }
`;

const VideoContainer = styled.video`
  height: 270px;
  width: 270px;
  margin: 2rem 0;
  border-radius: 50%;
  animation: ${jellyAnimation} 5s infinite; /* Adjust the duration for the jelly effect */
`;

export default function OurStory() {
    return (
        <div className='OurStory-main'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <div className='ourstory-left-box'>
                            <div>
                                <button className='hero-btn'>OUR TEAM</button> <br />
                                <VideoContainer
                                    src={v1}
                                    className='ourstory-video'
                                    autoPlay
                                    loop
                                />
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-6'>
                        <div className='text-white ourstory-right-box'>
                            <h1 className='ourstory-title'>Our Team Crafting <span className='hero-span'>Tomorrow's Wellness.</span></h1>
                            <p className='ourstory-text'>Our cohesive team of healthcare professionals is driven by expertise and a shared commitment to transforming the landscape of medical care. With diverse skills and a passion for innovation, we collaborate seamlessly to deliver cutting-edge solutions. Our collective dedication ensures that every member brings a unique perspective, contributing to a dynamic and forward-thinking approach. Together, we strive to make a lasting impact on healthcare, providing you with the highest standard of personalized, intelligent, and compassionate service.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
