import React, { useState, useRef, useEffect } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Tooltip } from 'react-tooltip';
import axios from 'axios';
import { toast } from 'react-toastify';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

import './AddUser.css'
import { storage } from '../../../config/firebase';
import { useStateContext } from '../../../context/AppContext';

export default function AddUser() {
    const { userToken } = useStateContext()
    const fileInputRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const [pageLoading, setpageLoading] = useState(false)
    const [data, setdata] = useState([])

    const [name, setname] = useState('')
    const [regBody, setregBody] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [question, setquestion] = useState('')
    const [answer, setanswer] = useState('')
    const [imgFile, setimgFile] = useState('')

    const [nameError, setnameError] = useState('')
    const [emailError, setemailError] = useState('')
    const [passwordError, setpasswordError] = useState('')
    const [questionError, setquestionError] = useState('')
    const [answerError, setanswerError] = useState('')
    const [regBodyError, setregBodyError] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handleClick = (event) => {
        event.preventDefault();
        fileInputRef.current.click();
    };

    const handleNameChange = (e) => {
        setname(e.target.value)
        const nameRegex = /^[A-Za-z ]{4,}$/;
        if (!nameRegex.test(e.target.value)) {
            return setnameError('Please enter only letters and the name must be at least 4 characters long')
        }
        setnameError('')
    }
    const handleEmailChange = (e) => {
        setemail(e.target.value)
        const emailRegex = /^[a-zA-Z0-9_.]+@[a-zA-Z]+\.[a-zA-Z.]+$/;
        if (!emailRegex.test(e.target.value)) {
            return setemailError('Please enter a valid email address.')
        }
        setemailError('')
    }
    const handlePasswordChange = (e) => {
        setpassword(e.target.value)
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!strongPasswordRegex.test(e.target.value)) {
            return setpasswordError(`Password must meet the following requirements: (1) Be at least 8 characters long. (2) Contain at least one lowercase letter. (3) Contain at least one uppercase letter. (4) Contain at least one digit. (5) Contain at least one special character (e.g., @$!%*?&). (6) Avoid using spaces`)
        }
        setpasswordError('')
    }
    const handleQuestionChange = (e) => {
        setquestion(e.target.value)
        if (!e.target.value) {
            return setquestionError('Please select the question')
        }
        setquestionError('')
    }
    const handleAnswerChange = (e) => {
        setanswer(e.target.value)
        if (!e.target.value) {
            return setanswerError('Please enter the answer')
        }
        setanswerError('')
    }

    const handleRegBodyChange = (e) => {
        setregBody(e.target.value)
        const regulatoryBodyRegex = /^[a-zA-Z0-9\s]+$/;
        if (!regulatoryBodyRegex.test(e.target.value)) {
            return setregBodyError('Please enter a valid regulatory body')
        }
        setregBodyError('')
    }

    const uploadDocument = (file) => {
        return new Promise((resolve, reject) => {

            const storageRef = ref(storage, `documents/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    // const progress =
                    //     Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                },
                (error) => {

                    console.log(error)
                    reject(error)
                },
                async () => {
                    const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)

                    resolve(downloadUrl)
                }
            );
        })
    }


    const Submit = async () => {
        if (!name) { return setnameError("Please enter the name") }
        if (!regBody) { return setregBodyError("Please enter a valid regulatory body") }
        if (!email) { return setemailError("Please enter the email") }
        if (!password) { return setpasswordError("Please enter the password") }
        if (!question) { return setquestionError("Please select the question") }
        if (!answer) { return setanswerError("Please enter the answer") }

        if (nameError || regBodyError || emailError || passwordError || questionError || answerError)
            return

        setIsLoading(true)

        let data
        if (imgFile instanceof File) {
            const storageReferenceName = uuidv4();
            const url = await uploadDocument(imgFile, storageReferenceName);
            data = { name, regBody, email, password, question, answer, image: url }
        } else {
            data = { name, regBody, email, password, question, answer, image: "" }
        }

        try {
            let user = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, data)
            const tokenReturned = user?.data?.token
            if (!tokenReturned) {
                toast.error('Invalid Credentials')
            } else {

                navigate('/admin/users')
                setIsLoading(false)
                toast.success('Registered Successfully!')
                setemail('')
                setname('')
                setregBody('')
                setpassword('')
                setquestion('')
                setanswer('')
                setimgFile('')
            }


        } catch (error) {
            console.log(error)
            if (error?.response?.status === 500) {
                toast.error("Error while adding new user")
            } else {
                toast.error('Something Went Wrong')
            }
        }
        finally {
            setIsLoading(false)
        }
    }

    const compareFields = () => {
        return (
            name !== data?.name || email !== data?.email || password !== data?.password
            || imgFile !== data?.image || question !== data?.question || answer !== data?.answer
            || regBody !== data?.regulatoryBody
        );
    };

    const handleUpdate = async () => {
        if (!compareFields()) {
            toast.error(`No changes detected. Please make changes before updating.`, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
        if (!name) { return setnameError("Please enter the name") }
        if (!regBody) { return setregBodyError("Please enter a valid regulatory body") }
        if (!email) { return setemailError("Please enter the email") }
        if (!password) { return setpasswordError("Please enter the password") }
        if (!question) { return setquestionError("Please select the question") }
        if (!answer) { return setanswerError("Please enter the answer") }

        if (nameError || emailError || passwordError || questionError || answerError || regBodyError)
            return

        setIsLoading(true)

        let data
        if (imgFile instanceof File) {
            const storageReferenceName = uuidv4();
            const url = await uploadDocument(imgFile, storageReferenceName);
            data = { name, regulatoryBody: regBody, email, password, question, answer, image: url }
        } else {
            data = { name, regulatoryBody: regBody, email, password, question, answer, image: imgFile ? imgFile : '' }
        }

        axios.put(`${process.env.REACT_APP_API_URL}/auth/profile/${location?.state?.id}`, data, { headers: { token: userToken } })
            .then((response) => {
                toast.success(`Profile Updated Successfully!`, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setIsLoading(false)
                navigate('/admin/users')
            })
            .catch((error) => { console.log(error); setIsLoading(false) })
    }

    useEffect(() => {
        if (location?.state?.id) {
            setpageLoading(true)
        }

        axios.get(`${process.env.REACT_APP_API_URL}/auth/get-user`, { headers: { token: userToken } })
            .then((res) => {
                const filter = res.data.filter((t, i) => t._id === location?.state?.id)
                setname(filter[0]?.name); setemail(filter[0]?.email); setpassword(filter[0]?.password)
                setquestion(filter[0]?.question); setanswer(filter[0]?.answer); setimgFile(filter[0]?.image)
                setregBody(filter[0]?.regulatoryBody); setdata(filter[0])
                setpageLoading(false);
            })
            .catch((error) => console.log(error));
    }, [location?.state?.id,userToken])
    return (
        <div className='admin-addUser'>
            {
                pageLoading ? <div className='addUser-pageLoader'>
                    <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
                    :
                    <div className='admin-card shadow'>
                        <div className='admin-cardHead'>
                            <div className='d-flex justify-content-between align-content-center'>
                                <h5 className='admin-card-title'>{location?.state?.id ? 'UPDATE' : 'ADD'} USER</h5>
                                <Link to='/admin/users' className='admin-cardBtn'>View Users</Link>
                            </div>
                        </div>
                        <div className='admin-cardBody'>
                            <h5 className='admin-cardBody-title'>Personal Details</h5>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <p className='admin-card-label'>NAME*</p>
                                    <div className='admin-card-div'>
                                        <input className='admin-card-input' style={{ border: nameError && '1px solid #e4002b' }} type='text' value={name} onChange={handleNameChange} placeholder='Name' />
                                        {nameError && (
                                            <i
                                                className="fa-solid fa-circle-info admin-error-icon"
                                                data-tooltip-id="my-tooltip1"
                                                data-tooltip-content={nameError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip1" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <p className='admin-card-label'>Registered Regulatory Body*</p>
                                    <div className='admin-card-div'>
                                        <input className='admin-card-input' style={{ border: regBodyError && '1px solid #e4002b' }} type='text' value={regBody} onChange={handleRegBodyChange} placeholder='Regulatory Body' />
                                        {regBodyError && (
                                            <i
                                                className="fa-solid fa-circle-info admin-error-icon"
                                                data-tooltip-id="my-tooltip7"
                                                data-tooltip-content={regBodyError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip7" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <p className='admin-card-label'>EMAIL*</p>
                                    <div className='admin-card-div'>
                                        <input className='admin-card-input' style={{ border: emailError && '1px solid #e4002b' }} type='email' value={email} onChange={handleEmailChange} placeholder='Email' />
                                        {emailError && (
                                            <i
                                                className="fa-solid fa-circle-info admin-error-icon"
                                                data-tooltip-id="my-tooltip2"
                                                data-tooltip-content={emailError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip2" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <p className='admin-card-label'>PASSWORD*</p>
                                    <div className='admin-card-div'>
                                        <div className='admin-card-input2' style={{ border: passwordError && '1px solid #e4002b' }}>
                                            <input type={showPassword ? 'text' : 'password'} value={password} onChange={handlePasswordChange} placeholder='Password' />
                                            <i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPassword(!showPassword)}></i>
                                        </div>
                                        {passwordError && (
                                            <i
                                                className="fa-solid fa-circle-info admin-error-icon"
                                                data-tooltip-id="my-tooltip3"
                                                data-tooltip-content={passwordError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip3" style={{ width: password ? '360px' : '200px' }} />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <p className='admin-card-label'>UPLOAD IMAGE</p>
                                    <div className='admin-card-uploadBtn'>
                                        <input
                                            id="fileInput"
                                            type="file"
                                            ref={fileInputRef}
                                            style={{ display: 'none' }}
                                            accept="image/jpeg, image/png"
                                            onChange={e => setimgFile(e.target.files[0])} />
                                        <input className='admin-card-input inpt2' disabled placeholder={imgFile ? (imgFile instanceof File ? imgFile.name : imgFile) : 'Upload Image'} />
                                        <button className='admin-card-Btn2' onClick={handleClick}>Upload</button>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <p className='admin-card-label'>QUESTION*</p>
                                    <div className='admin-card-div'>
                                        <select className='admin-card-input' style={{ border: questionError && '1px solid #e4002b' }} value={question} onChange={handleQuestionChange}>
                                            <option value=''>Security Question</option>
                                            <option value='What is the title of your favorite book?'>What is the title of your favorite book?</option>
                                            <option value='What is the name of the street you grew up on?'>What is the name of the street you grew up on?</option>
                                            <option value='What is the name of your primary school?'>What is the name of your primary school?</option>
                                        </select>
                                        {questionError && (
                                            <i
                                                className="fa-solid fa-circle-info admin-error-icon"
                                                data-tooltip-id="my-tooltip4"
                                                data-tooltip-content={questionError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip4" />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6 col-sm-12'>
                                    <p className='admin-card-label'>ANSWER*</p>
                                    <div className='admin-card-div'>
                                        <input className='admin-card-input' style={{ border: answerError && '1px solid #e4002b' }} type='text' value={answer} onChange={handleAnswerChange} placeholder='Security Answer' />
                                        {answerError && (
                                            <i
                                                className="fa-solid fa-circle-info admin-error-icon"
                                                data-tooltip-id="my-tooltip5"
                                                data-tooltip-content={answerError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip5" />
                                    </div>
                                </div>
                            </div>

                            <div className='admin-card-btn-div'>
                                {
                                    location?.state?.id ?
                                        <button className='admin-cardBtn3' disabled={isLoading} onClick={handleUpdate}>{isLoading ? 'Loading...' : 'Update'}</button>
                                        :
                                        <button className='admin-cardBtn3' disabled={isLoading} onClick={Submit}>{isLoading ? 'Loading...' : 'Submit'}</button>
                                }
                            </div>
                        </div>
                    </div>
            }
        </div>
    )
}
