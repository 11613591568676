import React, { useState, useEffect } from 'react'
import axios from 'axios';

import './Home.css'
import { useStateContext } from '../../../context/AppContext';
import ApexChart from '../../../components/ApexChart';

export default function Home() {
    const { userToken } = useStateContext()
    const [patients, setpatients] = useState([])
    const [todayPatients, settodayPatients] = useState([])
    const [monthPatients, setmonthPatients] = useState([])

    const [pageLoading, setpageLoading] = useState(true)

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/patient/get-date`, { headers: { token: userToken } })
            .then((res) => {
                setpatients(res?.data)

                const todayPatients = res?.data?.filter(patient => {
                    const createdAtDate = new Date(patient.createdAt).toLocaleDateString();
                    const todayDate = new Date().toLocaleDateString();
                    return createdAtDate === todayDate;
                });
                settodayPatients(todayPatients)

                const currentMonthPatients = res?.data?.filter(patient => {
                    const createdAtDate = new Date(patient.createdAt);
                    const currentMonth = new Date();
                    return (
                        createdAtDate.getMonth() === currentMonth.getMonth() &&
                        createdAtDate.getFullYear() === currentMonth.getFullYear()
                    );
                });
                setmonthPatients(currentMonthPatients)

                setpageLoading(false)
            })
            .catch((error) => console.log(error));
    }, [userToken])
    return (
        <div className='db-home-main'>
            {
                pageLoading ? <div className='addPatient-pageLoader'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                    :
                    <>
                        <h3 className='db-home-title'>Welcome To Dashboard</h3>
                        <div className='db-home-box'>
                            <div className='db-home-count shadow'>
                                Total Patients
                                <div className='icon-count'>
                                    <i className="fa-solid fa-users text-primary"></i> {patients?.length}
                                </div>
                            </div>
                            <div className='db-home-count shadow'>
                                Today's Patient Entries
                                <div className='icon-count'>
                                    <i className="fa-solid fa-users text-primary"></i> {todayPatients?.length}
                                </div>
                            </div>
                            <div className='db-home-count shadow'>
                                Monthly Patient Entries
                                <div className='icon-count'>
                                    <i className="fa-solid fa-users text-primary"></i> {monthPatients?.length}
                                </div>
                            </div>
                        </div>

                        <ApexChart Users={patients} />
                    </>
            }
        </div>
    )
}
