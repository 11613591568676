import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { deleteObject, ref } from 'firebase/storage';

import './Users.css'
import { storage } from '../../../config/firebase';
import { useStateContext } from '../../../context/AppContext';

export default function Users() {
    const { userToken } = useStateContext()
    const navigate = useNavigate();
    const [data, setdata] = useState([])
    const [data2, setdata2] = useState([])

    const [pageLoading, setpageLoading] = useState(true)

    const addDataAPI = data?.map((elem, i) => {
        const filter = data2.filter((t) => t.uid === elem._id)
        return {
            Name: `${elem.name}`,
            Email: `${elem.email}`,
            totalPatients: `${filter?.length}`,
            action: (
                <div>
                    <span>
                        <button target='_blank'
                            className="btn btn-success btn-xs"
                            style={{ width: 60, fontSize: 14, padding: 4, textDecoration: "none" }}
                            onClick={() => navigate('/admin/add-user', { state: { id: elem._id } })}
                        >
                            Edit
                        </button>
                    </span>{" "}
                    <span>
                        <button
                            className="btn btn-danger btn-xs"
                            style={{ width: 60, fontSize: 14, padding: 4 }}
                            onClick={() => handleDelete(elem)}
                        >
                            Delete
                        </button>
                    </span>
                </div>
            ),
        };
    });

    const addColumns = [
        {
            name: "Name",
            selector: (row) => row.Name,
            sortable: true,
            // center: true,
        },
        {
            name: "Email",
            selector: (row) => row.Email,
            sortable: true,
            // center: true,
        },
        {
            name: "Total Patients",
            selector: (row) => row.totalPatients,
            sortable: true,
            // center: true,
        },
        {
            name: "Action",
            selector: (row) => row.action,
            sortable: true,
            // center: true,
        },
    ];


    const deleteDocument = (filePath) => {
        return new Promise((resolve, reject) => {
            const storageRef = ref(storage, filePath);

            deleteObject(storageRef)
                .then(() => {
                    console.log("File deleted successfully");
                    resolve();
                })
                .catch((error) => {
                    console.error("Error deleting file", error);
                    reject(error);
                });
        });
    };

    const handleDelete = (elem) => {
        toast(
            <div className="custom-toast">
                <p className="message">Are you sure you want to delete this User?</p>
                <div className="button-container-toast">
                    <button className="button-toast yes" onClick={() => {
                        toast.dismiss();
                        axios.delete(`${process.env.REACT_APP_API_URL}/auth/delete/${elem._id}`, { headers: { token: userToken } })
                            .then((response) => {
                                const newData = data.filter((t) => (t._id !== elem._id))
                                setdata(newData)
                                toast.success(`User Deleted Successfully`, {
                                    position: "top-right",
                                    autoClose: 3000,
                                    hideProgressBar: false,
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                });
                                if (elem.image) {
                                    deleteDocument(elem.image)
                                        .then(() => { console.log("File deleted successfully") })
                                        .catch((error) => { console.error("Error deleting file", error) });
                                }
                            }).catch((err) => {
                                console.log(err)
                            })
                    }}>Yes</button>
                    <button className="button-toast no" onClick={() => {
                        toast.dismiss();
                    }}>No</button>
                </div>
            </div>
        );
    }

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/user-info`, { headers: { token: userToken } })
            .then((response) => {
                const filter = response?.data.filter((t) => t.isAdmin !== true)
                setdata(filter)
                setpageLoading(false)
            })
            .catch((error) => console.log(error));

        axios
            .get(`${process.env.REACT_APP_API_URL}/patient/get-date`, { headers: { token: userToken } })
            .then((res) => {
                setdata2(res?.data)
            })
            .catch((error) => console.log(error));
    }, [userToken])

    return (
        <div className='admin-users'>
            {
                pageLoading ? <div className='addPatient-pageLoader' style={{ backgroundColor: "#F5F6F8" }}>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                    :
                    <>
                        <div className='d-flex justify-content-between align-items-center'>
                            <h3 className='admin-users-title'>Total Users</h3>
                            <Link to='/admin/add-user' className='admin-addBtn shadow-sm'>Add User</Link>
                        </div>
                        <div className="border shadow-sm">
                            <DataTable
                                noHeader
                                pagination
                                paginationServer
                                columns={addColumns}
                                data={addDataAPI}
                                customStyles={{
                                    cells: {
                                        style: {
                                            fontWeight: '600',
                                        },
                                    }
                                }}
                                className="custom-datatable2"
                            />
                        </div>
                    </>
            }
        </div>
    )
}
