import { createContext, useContext, useState, useEffect } from "react"
import axios from "axios";
const AuthContext = createContext();

export default function AppContext({ children }) {
    const [isLoading, setisLoading] = useState(true)
    const [userToken, setuserToken] = useState('')
    const [isAdmin, setisAdmin] = useState(false)
    const [isAuthenticated, setisAuthenticated] = useState(false)

    const [imgChanged, setimgChanged] = useState(false)

    useEffect(() => {
        let token = localStorage.getItem("echo_token")

        if (token) {
            setuserToken(token)
            setisAuthenticated(true)
            setisLoading(false)
            axios.get(`${process.env.REACT_APP_API_URL}/auth/verify-user`, { headers: { token: token } })
                .then((res) => {
                    setisAdmin(res.data.isAdmin)
                })
                .catch((error) => {
                    console.log(error)
                    if(error){
                        alert("Please Login Again")
                    }
                });
        } else {
            setisAuthenticated(false)
            setisLoading(false)
        }
    }, [])
    return <AuthContext.Provider value={{
        isAuthenticated,
        setisAuthenticated,
        isAdmin,
        setisAdmin,
        userToken,
        setuserToken,
        isLoading,
        imgChanged,
        setimgChanged
    }}>
        {children}
    </AuthContext.Provider>
}


export const useStateContext = () => useContext(AuthContext);