import React, { useEffect } from 'react'
import { Element } from 'react-scroll';
import { useLocation, useNavigate } from 'react-router-dom'
import { Scroll, scroller } from 'react-scroll'

import './Home.css'
import Navbar from '../../components/Navbar'
import Hero from '../../components/Hero'
import WhoWeAre from '../../components/WhoWeAre'
import VideoPlay from '../../components/VideoPlay'
import OurStory from '../../components/OurStory'
import Applications from '../../components/Applications'
import Footer from '../../components/Footer'
import Faq from '../../components/Faq'

export default function Home() {
  const location = useLocation();
  const navigate = useNavigate()


  useEffect(() => {
    navigate('/', { state: { dataObj: null } })
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      scroller.scrollTo(`/${location?.state?.dataObj}`, {
        duration: 200,
        smooth: true,
        offset: -100,
      });
    }, 1000);
  }, [location?.state?.dataObj])

  return (
    <>
      <Navbar />
      <Element name='/home'><Hero /></Element>
      <Element name='/aboutus'><WhoWeAre /></Element>
      <VideoPlay />
      <Element name='/ourteam'><OurStory /></Element>
      <Element name='/testimonials'><Applications /></Element>
      <Element name='/faq'><Faq /></Element>
      <Footer />
    </>
  )
}
