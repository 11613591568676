import React, { useState, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { decodeToken } from "react-jwt";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import { v4 as uuidv4 } from 'uuid';

import Navbar from '../../components/Navbar'
import './Signup.css'
import useUser from '../../hooks/useUser';
import { useStateContext } from '../../context/AppContext';
import Footer from '../../components/Footer';
import { storage } from '../../config/firebase';

export default function Signup() {
    const fileInputRef = useRef(null);
    const navigate = useNavigate()
    const { setUser } = useUser()
    const { setisAuthenticated, setuserToken } = useStateContext()
    const [showPassword, setShowPassword] = useState(false);
    const [showPass2, setshowPass2] = useState(false)

    const [name, setname] = useState('')
    const [regBody, setregBody] = useState('')
    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')
    const [confirmPass, setconfirmPass] = useState('')
    const [question, setquestion] = useState('')
    const [answer, setanswer] = useState('')
    const [imgFile, setimgFile] = useState('')

    const [nameError, setnameError] = useState('')
    const [regBodyError, setregBodyError] = useState('')
    const [emailError, setemailError] = useState('')
    const [passwordError, setpasswordError] = useState('')
    const [confirmPassError, setconfirmPassError] = useState('')
    const [questionError, setquestionError] = useState('')
    const [answerError, setanswerError] = useState('')

    const [security, setsecurity] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const handleClick = (event) => {
        event.preventDefault();
        fileInputRef.current.click();
    };

    const uploadDocument = (file) => {
        return new Promise((resolve, reject) => {

            const storageRef = ref(storage, `documents/${file.name}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on("state_changed",
                (snapshot) => {
                    const progress =
                        Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

                },
                (error) => {

                    console.log(error)
                    reject(error)
                },
                async () => {
                    const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref)

                    resolve(downloadUrl)
                }
            );
        })
    }

    const handleNext = () => {
        if (!name) { return setnameError("Please enter the name") }
        if (!regBody) { return setregBodyError("Please enter a valid regulatory body") }
        if (!email) { return setemailError("Please enter the email") }
        if (!password) { return setpasswordError("Please enter the password") }
        if (password !== confirmPass) { return setconfirmPassError('Password and confirm password must be identical.') }

        if (nameError || emailError || passwordError || confirmPassError || regBodyError)
            return

        setsecurity(true)
    }

    const handleRegister = async () => {
        if (!question) { return setquestionError("Please select the question") }
        if (!answer) { return setanswerError("Please enter the answer") }

        if (questionError || answerError)
            return

        setIsLoading(true)

        let data
        if (imgFile) {
            const storageReferenceName = uuidv4();
            const url = await uploadDocument(imgFile, storageReferenceName);
            data = { name, regBody, email, password, question, answer, image: url }
        } else {
            data = { name, regBody, email, password, question, answer, image: '' }
        }

        try {
            let user = await axios.post(`${process.env.REACT_APP_API_URL}/auth/register`, data)
            const tokenReturned = user?.data?.token
            if (!tokenReturned) {
                toast.error('Invalid Credentials')
            } else {

                localStorage.setItem('echo_token', tokenReturned)
                const decodedToken = decodeToken(tokenReturned)
                navigate('/dashboard')
                setIsLoading(false)
                setisAuthenticated(true)
                setuserToken(tokenReturned)
                setUser({ ...decodedToken })
                toast.success('Registered Successfully!')
                setemail('')
                setname('')
                setregBody('')
                setpassword('')
                setquestion('')
                setanswer('')
                setimgFile('')
            }


        } catch (error) {
            console.log(error)
            if (error?.response?.status == 500) {
                toast.error("This email is already registered. Please use another email")
            } else {
                toast.error('Something Went Wrong')
            }
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleNameChange = (e) => {
        setname(e.target.value)
        const nameRegex = /^[A-Za-z ]{4,}$/;
        if (!nameRegex.test(e.target.value)) {
            return setnameError('Please enter only letters and the name must be at least 4 characters long')
        }
        setnameError('')
    }
    const handleEmailChange = (e) => {
        setemail(e.target.value)
        const emailRegex = /^[a-zA-Z0-9_.]+@[a-zA-Z]+\.[a-zA-Z.]+$/;
        if (!emailRegex.test(e.target.value)) {
            return setemailError('Please enter a valid email address.')
        }
        setemailError('')
    }
    const handlePasswordChange = (e) => {
        setpassword(e.target.value)
        const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        if (!strongPasswordRegex.test(e.target.value)) {
            return setpasswordError(`Password must meet the following requirements: (1) Be at least 8 characters long. (2) Contain at least one lowercase letter. (3) Contain at least one uppercase letter. (4) Contain at least one digit. (5) Contain at least one special character (e.g., @$!%*?&). (6) Avoid using spaces`)
        }
        setpasswordError('')
    }
    const handleConfirmPassChange = (e) => {
        setconfirmPass(e.target.value)
        if (password !== e.target.value) {
            return setconfirmPassError('Password and confirm password must be identical.')
        }
        setconfirmPassError('')
    }
    const handleQuestionChange = (e) => {
        setquestion(e.target.value)
        if (!e.target.value) {
            return setquestionError('Please select the question')
        }
        setquestionError('')
    }
    const handleAnswerChange = (e) => {
        setanswer(e.target.value)
        if (!e.target.value) {
            return setanswerError('Please enter the answer')
        }
        setanswerError('')
    }
    const handleRegBodyChange = (e) => {
        setregBody(e.target.value)
        const regulatoryBodyRegex = /^[a-zA-Z0-9\s]+$/;
        if (!regulatoryBodyRegex.test(e.target.value)) {
            return setregBodyError('Please enter a valid regulatory body')
        }
        setregBodyError('')
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <Navbar />
            <div className='signup-main'>
                <h1 className='signup-title'>Login / SignUp</h1>
                <div className="signup-breadcrumb">Home <i className="fa-solid fa-chevron-right signup-icon"></i> <span style={{ color: "#b2b2b2" }}>Login / Signup</span></div>
            </div>
            <div className='signup-body'>
                <div className='container'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-7 col-md-11 col-sm-10'>
                            <div className='signup-card shadow-lg'>
                                <h1 className='signup-header'>Register Form</h1>
                                <p className='signup-text'>Do not have an account?</p>
                                {
                                    !security ?
                                        <div className='signup-inp-box'>
                                            <div className='signup-input-div'>
                                                <input className='signup-input' style={{ border: nameError && '2px solid #e4002b' }} type='text' value={name} onChange={handleNameChange} placeholder='Name*' />
                                                {nameError && (
                                                    <i
                                                        className="fa-solid fa-circle-info error-icon"
                                                        data-tooltip-id="my-tooltip1"
                                                        data-tooltip-content={nameError}
                                                        data-tooltip-place="top"
                                                    ></i>
                                                )}
                                                <Tooltip id="my-tooltip1" />
                                            </div>
                                            <div className='signup-input-div'>
                                                <input className='signup-input' style={{ border: regBodyError && '2px solid #e4002b' }} type='text' value={regBody} onChange={handleRegBodyChange} placeholder='Registered Regulatory Body*' />
                                                {regBodyError && (
                                                    <i
                                                        className="fa-solid fa-circle-info error-icon"
                                                        data-tooltip-id="my-tooltip1"
                                                        data-tooltip-content={regBodyError}
                                                        data-tooltip-place="top"
                                                    ></i>
                                                )}
                                                <Tooltip id="my-tooltip1" />
                                            </div>
                                            <div className='signup-input-div'>
                                                <input className='signup-input' style={{ border: emailError && '2px solid #e4002b' }} type='email' value={email} onChange={handleEmailChange} placeholder='Email Id*' />
                                                {emailError && (
                                                    <i
                                                        className="fa-solid fa-circle-info error-icon"
                                                        data-tooltip-id="my-tooltip2"
                                                        data-tooltip-content={emailError}
                                                        data-tooltip-place="top"
                                                    ></i>
                                                )}
                                                <Tooltip id="my-tooltip2" />
                                            </div>
                                            <div className='signup-input-div'>
                                                <div className='signup-input2' style={{ border: passwordError && '2px solid #e4002b' }}>
                                                    <input type={showPassword ? 'text' : 'password'} value={password} onChange={handlePasswordChange} placeholder='Password*' />
                                                    <i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPassword(!showPassword)}></i>
                                                </div>
                                                {passwordError && (
                                                    <i
                                                        className="fa-solid fa-circle-info error-icon"
                                                        data-tooltip-id="my-tooltip3"
                                                        data-tooltip-content={passwordError}
                                                        data-tooltip-place="top"
                                                    ></i>
                                                )}
                                                <Tooltip id="my-tooltip3" style={{ width: password ? '360px' : '200px' }} />
                                            </div>
                                            <div className='signup-input-div'>
                                                <div className='signup-input2' style={{ border: confirmPassError && '2px solid #e4002b' }}>
                                                    <input type={showPass2 ? 'text' : 'password'} value={confirmPass} onChange={handleConfirmPassChange} placeholder='Confirm Password*' />
                                                    <i className={`fa-solid ${showPass2 ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setshowPass2(!showPass2)}></i>
                                                </div>
                                                {confirmPassError && (
                                                    <i
                                                        className="fa-solid fa-circle-info error-icon"
                                                        data-tooltip-id="my-tooltip4"
                                                        data-tooltip-content={confirmPassError}
                                                        data-tooltip-place="top"
                                                    ></i>
                                                )}
                                                <Tooltip id="my-tooltip4" style={{ width: confirmPass ? '360px' : '200px' }} />
                                            </div>
                                            <input
                                                id="fileInput"
                                                type="file"
                                                ref={fileInputRef}
                                                style={{ display: 'none' }}
                                                accept="image/jpeg, image/png"
                                                onChange={e => setimgFile(e.target.files[0])} />
                                            <button className='signup-btn2' onClick={handleClick}>{imgFile ? <span className='text-dark'>{imgFile?.name}</span> : <span className='text-secondary'>Upload Image (Optional)</span>}</button>
                                        </div>
                                        :
                                        <div className='signup-inp-box'>
                                            <div className='signup-input-div'>
                                                <select className='signup-input' style={{ border: questionError && '2px solid #e4002b' }} value={question} onChange={handleQuestionChange}>
                                                    <option value=''>Select Security Question!</option>
                                                    <option value='What is the title of your favorite book?'>What is the title of your favorite book?</option>
                                                    <option value='What is the name of the street you grew up on?'>What is the name of the street you grew up on?</option>
                                                    <option value='What is the name of your primary school?'>What is the name of your primary school?</option>
                                                </select>
                                                {questionError && (
                                                    <i
                                                        className="fa-solid fa-circle-info error-icon"
                                                        data-tooltip-id="my-tooltip5"
                                                        data-tooltip-content={questionError}
                                                        data-tooltip-place="top"
                                                    ></i>
                                                )}
                                                <Tooltip id="my-tooltip5" />
                                            </div>
                                            <div className='signup-input-div'>
                                                <input className='signup-input' style={{ border: answerError && '2px solid #e4002b' }} type='text' value={answer} onChange={handleAnswerChange} placeholder='Answer*' />
                                                {answerError && (
                                                    <i
                                                        className="fa-solid fa-circle-info error-icon"
                                                        data-tooltip-id="my-tooltip6"
                                                        data-tooltip-content={answerError}
                                                        data-tooltip-place="top"
                                                    ></i>
                                                )}
                                                <Tooltip id="my-tooltip6" />
                                            </div>
                                        </div>
                                }
                                {
                                    security ?
                                        <button className='signup-btn' disabled={isLoading} onClick={handleRegister}>{isLoading ? "Loading..." : 'Register'}</button>
                                        :
                                        <button className='signup-btn' onClick={handleNext}>Next</button>
                                }
                                <p className='signup-botm-text'>Already have an account.? <Link to='/login' style={{ color: "#3195D8", textDecoration: "none" }}>Login</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
