import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { truncate } from "lodash";
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-toastify';

import './ViewRes.css'
import { useStateContext } from '../../../context/AppContext';

export default function ViewRes() {
    const navigate = useNavigate();
    const location = useLocation();
    const { slug } = useParams()
    const { userToken } = useStateContext()
    const [aiReco, setaiReco] = useState([])
    const [filteredData, setfilteredData] = useState([])
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const [isLoading, setisLoading] = useState(true)

    const handleDelete = (elem) => {
        toast(
            <div className="custom-toast">
                <p className="message">Are you sure you want to delete this recommendation?</p>
                <div className="button-container-toast">
                    <button className="button-toast yes" onClick={() => {
                        toast.dismiss();
                        axios.delete(`${process.env.REACT_APP_API_URL}/recommendation/delete/${elem._id}`, { headers: { token: userToken } })
                            .then((response) => {
                                const newData = aiReco.filter((t) => (t._id !== elem._id))
                                setaiReco(newData)
                                setfilteredData(newData.slice(0, itemsPerPage))
                                setCurrentPage(1)
                                toast.success(`Recommendation Deleted Successfully`);
                            }).catch((err) => {
                                console.log(err)
                            })
                    }}>Yes</button>
                    <button className="button-toast no" onClick={() => {
                        toast.dismiss();
                    }}>No</button>
                </div>
            </div>
        );
    }

    const handleNext = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        setfilteredData(aiReco.slice(startIndex, endIndex));
        setCurrentPage(currentPage + 1);
    };

    const handlePrevious = () => {
        const startIndex =
            currentPage > 1 ? (currentPage - 2) * itemsPerPage : 0;
        const endIndex = startIndex + itemsPerPage;
        setfilteredData(aiReco.slice(startIndex, endIndex));
        setCurrentPage(currentPage - 1);
    };

    useEffect(() => {
        if (location?.state?.id) {
            axios
                .get(`${process.env.REACT_APP_API_URL}/recommendation/get-recos`, { headers: { token: userToken } })
                .then((response) => {
                    const filter2 = response.data.filter((t, i) => t.patientId === location?.state?.id)
                    const sortedFilter2 = filter2.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    setfilteredData(sortedFilter2.slice(0, itemsPerPage))
                    setaiReco(sortedFilter2);
                    setisLoading(false)
                })
                .catch((error) => console.log(error));
        }
    }, [location?.state?.id, userToken])


    return (
        <div className='db-viewRes-main'>
            <h3 className='db-home-title'>Previous Responses</h3>

            {
                isLoading ?
                    <div className='mt-5 d-flex justify-content-center'>
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    :
                    aiReco.length !== 0 ? (
                        <div className='vr-table'>
                            <table className='vr-table-div mb-0 mt-3'>
                                <thead>
                                    <tr>
                                        <th>Medical Condition</th>
                                        <th>Drug Name</th>
                                        <th>Dosage Plan</th>
                                        <th>Message</th>
                                        <th>Date</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        filteredData.map((t, i) => (
                                            <tr key={i} onClick={() => navigate(`/dashboard/patient-details/${t?.patientNo}?purpose=${encodeURIComponent('view-recommendations')}`, { state: { elem: t?._id } })}>
                                                <td className='vr-col1'>
                                                    <div className='d-flex flex-wrap'>
                                                        {t?.medicalConditions
                                                            && JSON.parse(t.medicalConditions).map((e, index, array) => (
                                                                <p className='mb-0' key={index}>
                                                                    {e}{index < array.length - 1 && ','}
                                                                </p>
                                                            ))}
                                                    </div>
                                                </td>
                                                <td className='vr-col2'>{t.recommendation['Drug Name']}</td>
                                                <td className='vr-col2'>
                                                    <span
                                                        data-tooltip-id={`my-tooltip${i + 1}`}
                                                        data-tooltip-content={t.recommendation['Dosage Plan']}
                                                        data-tooltip-place="top"
                                                    >
                                                        {truncate(t.recommendation['Dosage Plan'], { length: 60 })}
                                                    </span>
                                                    <Tooltip id={`my-tooltip${i + 1}`}
                                                        style={{
                                                            width: "360px",
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    />
                                                </td>
                                                <td className='vr-col2'>
                                                    <span
                                                        data-tooltip-id={`my-tooltip${i + 1}`}
                                                        data-tooltip-content={t.message}
                                                        data-tooltip-place="top"
                                                    >
                                                        {truncate(t.message, { length: 60 })}
                                                    </span>
                                                    <Tooltip id={`my-tooltip${i + 1}`}
                                                        style={{
                                                            width: "360px",
                                                            whiteSpace: 'pre-wrap',
                                                        }}
                                                    />
                                                </td>
                                                <td className='vr-col3'>{new Date(t.createdAt).toLocaleString()}</td>
                                                <td className='vr-col4'>{
                                                    <div className='d-flex gap-3'>
                                                        <i className="fa-solid fa-trash-can vr-trash-btn"
                                                            onClick={(event) => {
                                                                event.stopPropagation();
                                                                handleDelete(t);
                                                            }}
                                                        ></i>
                                                    </div>
                                                }</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            </table>
                        </div>
                    )
                        :
                        <div className='text-center border p-5'>No records are available</div>

            }

            <div className='pagination-buttons mb-3'>
                <button onClick={handlePrevious} className='btn' disabled={currentPage === 1}>
                    Previous
                </button>
                <div>
                    Page {currentPage} of {Math.ceil(aiReco.length / itemsPerPage)}
                </div>
                <button className='btn' onClick={handleNext} disabled={currentPage * itemsPerPage >= aiReco.length}>
                    Next
                </button>
            </div>
        </div>
    )
}
