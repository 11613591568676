import React from 'react'
import { useNavigate } from 'react-router-dom';

import './Recommendation.css'

export default function Recommendation() {
    const navigate = useNavigate();

    return (
        <div className='reco-main'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='reco-card shadow' style={{ backgroundColor: "#0B2643", color: "white" }} onClick={() => navigate('/dashboard/add-patient')}>
                            <h2>Add Patient</h2><i className="fa-solid fa-hospital-user fs-3"></i>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='reco-card shadow' style={{ backgroundColor: "#6ED3B2", color: "white" }} onClick={() => navigate(`/dashboard/patient/${'edit-info'}`)}>
                            <h2>Edit Patient</h2><i className="fa-solid fa-pen-to-square fs-3"></i>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='reco-card shadow' style={{ backgroundColor: "#DC3545", color: "white" }} onClick={() => navigate(`/dashboard/patient/${'delete-info'}`)}>
                            <h2>Delete Patient</h2><i className="fa-solid fa-trash-can fs-3"></i>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='reco-card reco-card-2 shadow' style={{ backgroundColor: "#3A76EF", color: "white" }} onClick={() => navigate(`/dashboard/select-reco`)}>
                            <h3>Patient Recommendations</h3><i className="fa-solid fa-notes-medical fs-3"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
