import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import './WhoWe.css'

export default function WhoWeAre() {

    useEffect(() => {
        AOS.init({
            duration: 500,
            offset: 200,
            delay: 100,
        });
    }, []);
    return (
        <div className='wwa-main'>
            <div className='container'>
                <div className='row d-flex justify-content-center'>
                    <div className='col-11 col-md-11 col-sm-9 col-9'>
                        <div className='d-flex justify-content-center'><button className='wwa-btn'>ABOUT US</button></div>
                        {/* <h1 className='wwa-title' data-aos="fade-up">Don't Hesitate To Adopt A New Technology <span className='hero-span'>AI That </span>Will Improve You Business Growth And Think Smart And Make Big Change Get <span className='hero-span'>Big Change</span></h1> */}
                        <h1 className='wwa-title'>At ECHO AI, we're on a mission to redefine healthcare. With a focus on <span className='hero-span'>innovation</span> and personalized solutions, we aim to empower individuals with intelligent and accessible medical care, ensuring a healthier and happier <span className='hero-span'>future for all.</span></h1>
                        {/* <div className='wwa-img'>
                            <div>
                                <img src={img1} />
                                <p className='wwa-text'>Hendry Farson <span className='wwa-span'>- CEO</span></p>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
