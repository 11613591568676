import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from 'axios';
import { decodeToken } from "react-jwt";
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import Navbar from '../../components/Navbar'
import useUser from '../../hooks/useUser';
import { useStateContext } from '../../context/AppContext';
import Footer from '../../components/Footer';

export default function Signin() {
    const navigate = useNavigate()
    const { setUser } = useUser()
    const { setisAuthenticated, setisAdmin, setuserToken } = useStateContext()
    const [showPassword, setShowPassword] = useState(false);

    const [email, setemail] = useState('')
    const [password, setpassword] = useState('')

    const [emailError, setemailError] = useState('')
    const [passwordError, setpasswordError] = useState('')

    const [isLoading, setIsLoading] = useState(false)

    const handleLogin = async () => {
        if (!email) { return setemailError("enter the email") }
        if (!password) { return setpasswordError("enter the password") }

        if (emailError || passwordError)
            return

        const data = { email, password }

        try {
            setIsLoading(true)
            let user = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, data)

            const tokenReturned = user?.data?.token
            if (!tokenReturned) {
                toast.error('Invalid Credentials')
            } else {

                localStorage.setItem('echo_token', tokenReturned)
                const decodedToken = decodeToken(tokenReturned)
                setUser({ ...decodedToken })
                if (user?.data?.isAdmin) {
                    setisAdmin(true)
                    navigate('/admin')
                } else {
                    navigate('/dashboard')
                }
                setisAuthenticated(true)
                setuserToken(tokenReturned)
                toast.success('Logged In')
                setemail('')
                setpassword('')

            }


        } catch (error) {
            console.log(error)
            if (error?.response?.status == 500) {
                toast.error("This email is not registered. Please register your email !!")
            } else if (error?.response?.status == 401) {
                toast.error("Invalid credentials. Please check your email and password and try again.")
            } else {
                toast.error('Something Went Wrong')
            }
        }
        finally {
            setIsLoading(false)
        }
    }

    const handleEmailChange = (e) => {
        setemail(e.target.value)
        const emailRegex = /^[a-zA-Z0-9_.]+@[a-zA-Z]+\.[a-zA-Z.]+$/;
        if (!emailRegex.test(e.target.value)) {
            return setemailError('Please enter a valid email address.')
        }
        setemailError('')
    }

    const handlePasswordChange = (e) => {
        setpassword(e.target.value)
        if (e.target.value.length < 8) {
            return setpasswordError(`Password must be at least 8 characters long.`)
        }
        setpasswordError('')
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Navbar />
            <div className='signup-main'>
                <h1 className='signup-title'>Login / SignUp</h1>
                <div className="signup-breadcrumb">Home <i className="fa-solid fa-chevron-right signup-icon"></i> <span style={{ color: "#b2b2b2" }}>Login / Signup</span></div>
            </div>
            <div className='signup-body'>
                <div className='container'>
                    <div className='row d-flex justify-content-center'>
                        <div className='col-lg-7 col-md-11 col-sm-10'>
                            <div className='signup-card shadow-lg'>
                                <h1 className='signup-header'>Welcome Back</h1>
                                <p className='signup-text'>Please enter your login credentials to access your account.</p>
                                <div className='signup-inp-box mb-4'>
                                    <div className='signup-input-div'>
                                        <input className='signup-input' style={{ border: emailError && '2px solid #e4002b' }} type='email' value={email} onChange={handleEmailChange} placeholder='Email' />
                                        {emailError && (
                                            <i
                                                className="fa-solid fa-circle-info error-icon"
                                                data-tooltip-id="my-tooltip1"
                                                data-tooltip-content={emailError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip1" />
                                    </div>
                                    <div className='signup-input-div'>
                                        <div className='signup-input2' style={{ border: passwordError && '2px solid #e4002b' }}>
                                            <input type={showPassword ? 'text' : 'password'} value={password} onChange={handlePasswordChange} placeholder='Password*' />
                                            <i className={`fa-solid ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPassword(!showPassword)}></i>
                                        </div>
                                        {passwordError && (
                                            <i
                                                className="fa-solid fa-circle-info error-icon"
                                                data-tooltip-id="my-tooltip2"
                                                data-tooltip-content={passwordError}
                                                data-tooltip-place="top"
                                            ></i>
                                        )}
                                        <Tooltip id="my-tooltip2" />
                                    </div>
                                </div>
                                <button className='signup-btn' disabled={isLoading} onClick={handleLogin}>Sign In</button>
                                <p className='signup-botm-text'><Link to='/forgot-password' style={{ color: "#3195D8", textDecoration: "none" }}>Forgot Password</Link></p>
                                <p className='signup-botm-text'>Don't have an account.? <Link to='/signup' style={{ color: "#3195D8", textDecoration: "none" }}>Signup</Link></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
