import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios'

import './Dashboard.css'
import Home from './Home'
import useUser from '../../hooks/useUser'
import { useStateContext } from '../../context/AppContext'
import Recommendation from './Recommendation'
import AddPatient from './AddPatient'
import PatientDetail from './PatientDetail'
import PatientActions from './PatientActions'
import Profile from './Profile'
import ViewRes from './ViewRes/ViewRes'
import SelectReco from './SelectReco/SelectReco'
import Calculator from './Calculator/Calculator';

export default function Dashboard() {
  const { setisAuthenticated, userToken, setuserToken, imgChanged } = useStateContext();
  const { setUser } = useUser()
  const navigate = useNavigate()
  const [sidebar, setsidebar] = useState(true)
  const [activebtn, setactivebtn] = useState('home')
  const [imgUrl, setimgUrl] = useState('')

  const [currentDate, setCurrentDate] = useState('');

  const handleChange = (e) => {
    if (e === 'home') { setactivebtn('home'); navigate('/dashboard/') }
    if (e === 'guideline') { setactivebtn('guideline'); navigate('/dashboard/guidelines') }
    if (e === 'recommendation') { setactivebtn('recommendation'); navigate('/dashboard/patient-management') }
    if (e === 'logout') {

      toast(
        <div className="custom-toast">
          <p className="message">Are you sure you want to logout?</p>
          <div className="button-container-toast">
            <button className="button-toast yes" onClick={() => {
              toast.dismiss();
              setactivebtn('logout');
              localStorage.removeItem('echo_token')
              localStorage.removeItem('echo_uid')
              setUser(null)
              setisAuthenticated(false)
              setuserToken('')
              navigate('/')
            }}>Yes</button>
            <button className="button-toast no" onClick={() => {
              toast.dismiss();
            }}>No</button>
          </div>
        </div>
      );
    }
  }

  const updateDate = () => {
    const now = new Date();

    const dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const dateString = now.toLocaleDateString(undefined, dateOptions);

    setCurrentDate(dateString);
  };

  useEffect(() => {
    const path = window.location.pathname
    if (path === '/dashboard/') { setactivebtn('home') }
    if (path === '/dashboard/guidelines') { setactivebtn('guideline') }
    if (path === '/dashboard/patient-management') { setactivebtn('recommendation') }


    window.scrollTo(0, 0);

    updateDate();
    const intervalId = setInterval(updateDate, 1000 * 60);
    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/auth/get-user`, { headers: { token: userToken } })
      .then((res) => {
        setimgUrl(res?.data?.image)
      })
      .catch((error) => {
        console.log(error)
      });
  }, [userToken, imgChanged])


  return (
    <div className='dashboard-main'>
      <div className={`sidebar ${sidebar ? 'open' : ''}`}>
        <h2 className='sidebar-logo' onClick={() => navigate('/')}>ECHO AI<i className="fa-solid fa-plus text-danger"></i></h2>
        <div className='sidebar-body'>
          <div className='sidebar-box' onClick={() => handleChange('home')} style={{ color: activebtn === 'home' ? 'white' : '#AAB7E0' }}><i className="fa-solid fa-house"></i><span>Home</span></div>
          <div className='sidebar-box' onClick={() => handleChange('recommendation')} style={{ color: activebtn === 'recommendation' ? 'white' : '#AAB7E0' }}><i className="fa-solid fa-hand-holding-medical"></i><span>Patient Management</span></div>
          <div className='sidebar-box' onClick={() => handleChange('logout')} style={{ color: activebtn === 'logout' ? 'white' : '#AAB7E0' }}><i className="fa-solid fa-arrow-right-from-bracket"></i><span>Logout</span></div>
        </div>
      </div>
      <div className='dashboard-body'>
        <div className='dashboard-nav shadow-sm'>
          {sidebar ? <i className="fa-solid fa-arrow-left" onClick={() => setsidebar(!sidebar)}></i> : <i className="fa-solid fa-arrow-right" onClick={() => setsidebar(!sidebar)}></i>}
          <div className='d-flex'>
            <div className="nav-date">
              {currentDate}
            </div>
            {imgUrl ? <img src={imgUrl} alt='Loading...' className='nav-img' onClick={() => navigate('/dashboard/profile')} /> : <i className="fa-solid fa-user" onClick={() => navigate('/dashboard/profile')}></i>}
          </div>
        </div>
        <div className='dashboard-link'>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/patient-management' element={<Recommendation />} />
            <Route path='/add-patient' element={<AddPatient />} />
            <Route path='/patient-details/:id' element={<PatientDetail />} />
            <Route path='/patient/:slug' element={<PatientActions />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/pre-responses/:slug' element={<ViewRes />} />
            <Route path='/select-reco' element={<SelectReco />} />
            <Route path='/converter' element={<Calculator />} />
          </Routes>
        </div>
      </div>
    </div>
  )
}
